.TongQuan {
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
  padding-left: 100px;
  padding-right: 100px;
}

.left {
  width: 20%;
  border-left: 1px solid #C3C3C3;
  padding-left: 10px;

  hr {
    margin: 0;
    width: 60%;
    border: 2px solid var(--color-text);
    opacity: 1;
    margin-bottom: 40px;
  }
}

.left .inner .title {
  font-size: 24px;
  font-weight: 600;
  color: black;
  margin-bottom: 5px;
}

.left .inner .time {
  font-size: 24px;
  font-weight: 500;
  color: #35973F;
  margin-bottom: 40px;
}

.right {
  width: 80%;
  border-left: 1px solid #C3C3C3;
  padding-left: 10px;
  border-right: 1px solid #C3C3C3;
  padding-right: 10px;

  pre {
    color: black;
    font-size: 22px;
    font-weight: 500;
    text-align: justify;
    font-family: 'montserrat', sans-serif;
  }
}

.description {
  white-space: pre-wrap; // Giữ nguyên ngắt dòng và cho phép ngắt dòng tự động
  word-wrap: break-word; // Tự động ngắt dòng khi từ quá dài
}

@media screen and (max-width: 1500px) {
.TongQuan {
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
  padding-left: 100px;
  padding-right: 100px;
}

.left {
  width: 20%;
  border-left: 1px solid #C3C3C3;
  padding-left: 10px;

  hr {
    margin: 0;
    width: 60%;
    border: 2px solid var(--color-text);
    opacity: 1;
    margin-bottom: 40px;
  }
}

.left .inner .title {
  font-size: 20px;
  font-weight: 600;
  color: black;
  margin-bottom: 5px;
}

.left .inner .time {
  font-size: 20px;
  font-weight: 500;
  color: #35973F;
  margin-bottom: 40px;
}

.right {
  width: 80%;
  border-left: 1px solid #C3C3C3;
  padding-left: 10px;
  border-right: 1px solid #C3C3C3;
  padding-right: 10px;

  pre {
    color: black;
    font-size: 18px;
    font-weight: 500;
    text-align: justify;
    font-family: 'montserrat', sans-serif;
  }
}

.description {
  white-space: pre-wrap; // Giữ nguyên ngắt dòng và cho phép ngắt dòng tự động
  word-wrap: break-word; // Tự động ngắt dòng khi từ quá dài
}

}

@media screen and (max-width: 1500px) {
  .TongQuan {
    display: flex;
    flex-wrap: wrap;
    padding-top: 40px;
    padding-left: 100px;
    padding-right: 100px;
  }
  
  .left {
    width: 20%;
    border-left: 1px solid #C3C3C3;
    padding-left: 10px;
  
    hr {
      margin: 0;
      width: 60%;
      border: 2px solid var(--color-text);
      opacity: 1;
      margin-bottom: 40px;
    }
  }
  
  .left .inner .title {
    font-size: 18px;
    font-weight: 600;
    color: black;
    margin-bottom: 5px;
  }
  
  .left .inner .time {
    font-size: 18px;
    font-weight: 500;
    color: #35973F;
    margin-bottom: 40px;
  }
  
  .right {
    width: 80%;
    border-left: 1px solid #C3C3C3;
    padding-left: 10px;
    border-right: 1px solid #C3C3C3;
    padding-right: 10px;
  
    pre {
      color: black;
      font-size: 16px;
      font-weight: 500;
      text-align: justify;
      font-family: 'montserrat', sans-serif;
    }
  }
  
  .description {
    white-space: pre-wrap; // Giữ nguyên ngắt dòng và cho phép ngắt dòng tự động
    word-wrap: break-word; // Tự động ngắt dòng khi từ quá dài
  }
}

@media screen and (max-width: 1199.8px) {
  .info .contact {
    align-items: start;
  }
  .info .title {
    font-size: 16px;
  }
  
  .info .buttons .button {
    padding: 10px 15px;
    font-size: 15px;
  }

  .TongQuan {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media screen and (max-width: 991.8px) {
  .TongQuan {
    padding-left: 40px;
    padding-right: 40px;
  }

  .left .inner .title {
    font-size: 16px;
  }
  
  .left .inner .time {
    font-size: 16px;
  }
}

@media screen and (max-width: 767.8px){
  .TongQuan {
    padding-left: 20px;
    padding-right: 20px;
  }

  .left {
    width: 30%;
  }
  .right {
    width: 70%;
  }
  .info {
    width: 100%;
  }
  
}

@media screen and (max-width: 575.8px){
  
}