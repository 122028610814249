body {
  background-color: #EEF4F0;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 4rem; /* Khoảng cách từ đỉnh viewport */
  z-index: 1; /* Đảm bảo nó nằm trên các phần tử khác */
}

.container {
  width: 100%;
  height: 100%;
}

.container2 {
  margin-top: 1rem;
  width: 100%;
  height: auto;
  border-radius: 20px;
  background-color: white;
  padding-bottom: 1rem;
}

.container3 {
  margin-top: 0;
  width: 100%;
  height: auto;
  border-radius: 20px;
  background-color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 1px solid #e8e8e8;
}

.container3-top {
  display: flex;
  margin-left: 1rem;
}

.container3-top-avatar img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}

.container3-top button {
  width: 89%;
  height: 3rem;
  border-radius: 25px;
  background-color: white;
  border: 1px solid rgb(101, 100, 100);
  margin-left: 0.7rem;
  text-align: center;
  padding-left: 15px;
  font-weight: 500;
  color: rgb(101, 100, 100);
}

.container4 {
  margin-top: 1rem;
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.black-line {
  border: none;
  border-top: 1px solid black;
}

@media (max-width: 500px) {
  .container3-top {
    margin-left: 1rem;
  }
}