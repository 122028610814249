.container {
  width: 100%;
  padding: 0px 100px;
  padding-bottom: 50px;
}

@media screen and (max-width: 991.8px) {
  .container {
    width: 100%;
    padding: 0px 50px;
  }
}

@media screen and (max-width: 767.8px){
  .container {
    width: 100%;
    padding: 0px 30px;
  }
}

@media screen and (max-width: 575.8px){
  
}