.container1 {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background-color: white;
    padding-top: 0;
    padding-bottom: 1rem;
    position: relative; 
    border: 1px solid #e8e8e8;
}

.container1-background img{
    margin-top: 0;
    background: linear-gradient(to right, rgb(71, 151, 81), rgb(138, 196, 146));
    height: 6rem;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 1;
    width: 100%;
}

.container1-avatar img{
    margin-left: auto;
    margin-right: auto;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    border: 2px solid white; 
    position: absolute; 
    top: 2.5rem; 
    left: 50%;
    transform: translateX(-50%); 
    z-index: 2;
    object-fit: cover;
} .container1-information {
    margin-top: 5rem;
}.container1-information-name {
    margin-right: auto;
    text-align: center;
    width: 100%;
    font-size: large;
    font-weight: bold;
}
.container1-information-linkuser {
    margin-right: auto;
    text-align: center;
    width: 100%; 
}.container1-information-content {
    margin-top: 1rem;
    margin-left: 2rem;
    width: 85%; 
}.container1-information-content button{
    width: 96%; 
    height: 2rem;
    background-color: #35973F;
    color: #EEF4F0;
    border-radius: 10px;
    border: none;
}