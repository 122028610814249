.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    position: relative;
    background: white;
    padding: 1rem;
    padding-bottom: 0.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 550px;
    max-height: 80vh; 
    display: flex;
    flex-direction: column;
}

.closeButton {
    position: sticky;
    top: 0;
    right: 0;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    margin-bottom: 0;
    z-index: 1001;
    align-self: flex-end;
}

.black-line {
    border: none;
    border-top: 1px solid black;
    position: sticky;
    top: 0;
    z-index: 1000;
    margin-top: 0rem;
}

.modalContentWrapper {
    overflow-y: auto; 
    flex: 1;
    padding-bottom: 1rem; /* Tạo khoảng trống cho thanh event */
}

.modalContent {
    margin-top: 0.3rem; 
    display: flex;
    padding-bottom: 0;
}

.modalContent img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    display: block;
    margin: 0 auto;
}

.modalContentinfo {
    margin-left: 0.7rem;
    background-color: rgb(237, 235, 235);
    border-radius: 14px;
    width: 90%;
    padding: 5px 10px 0px 10px;
}

.event {
    position: sticky;
    bottom: 0;
    background: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
    margin-top: auto;
}.event-head {
    width: 100%;
    display: flex;
}.event img {
    margin-top: 1rem;
    width: 40%;
    height: auto;
    max-height: 200px;
    border-radius: 15px;
}

.event input {
    border-radius: 8px;
    width: 90%;
    border: 1px solid transparent; 
    margin-right: 0.3rem;
    margin-left: 0.1rem;
    padding-left: 0.5rem; 
    padding-right: 0.3rem;
    transition: border-color 0.3s; 
}

.event input:focus {
    outline: none; 
    border-color: #37be54; 
}

.event i {
    font-size: 1.5rem;
    margin-right: 0.3rem;
    color: rgb(66, 66, 66);
}

.ContentRight {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: auto;
    margin-bottom: 0;
}

.time {
    margin-left: 1.2rem;
}

.commentContent img {
    width: 50%;
    height: auto;
    max-height: 250px;
    border-radius: 10px;
    margin-left: 0rem;
    margin-bottom: 0.6rem;
    margin-top: 0;
}