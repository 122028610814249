.container-post {
    width: 100%;
    height: auto;
    background-color: white;
    border-radius: 15px;
    padding-top: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border: 1px solid #e8e8e8;
}

.post-header {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.post-header-avatar img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.post-header-info {
    margin-left: 1rem;
}

.post-header-info h6 {
    margin: 0;
}

.post-header-info span {
    color: grey;
}

.post-header-option {
    margin-left: auto;
    margin-right: 1.3rem;
    position: relative;
}.post-header-option img{
    width: 1.5rem;
    height: auto;
}

.options-menu {
    position: absolute;
    top: 25px;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    width: 150px;
    padding: 0.5rem;
}

.options-menu p {
    margin: 0;
    padding: 0.5rem;
    cursor: pointer;
}

.options-menu p:hover {
    background-color: #f0f0f0;
}

.post-content {
    width: 95%;
    margin-left: 20px;
    margin-top: 1rem;
    font-weight: 500;
    font-size: 1.2rem;
}

.post-body {
    width: 100%;
    height: auto;
    padding-left: auto;
    padding-right: auto;
    text-align: center; 
    background-color: rgba(238, 255, 239, 0.705);
}

.post-body img {
    width: 100%;
    height: auto;
    max-height: 500px;
    max-width: 100%;
    display: block; 
    margin: 0 auto; 
}

.post-footer {
    width: 95%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.post-footer-top {
    display:flex ;
    margin-left: 2rem;
    padding-bottom: 0;
    margin-bottom: 0;
}

.post-footer-middle {
    display:flex ;
    margin-left: 1rem;
    font-size: 1.1rem;
    color: green;
}

.post-footer-middle p {
    margin-left: 1rem;
}

.post-footer-footer {
    display:flex ;
    margin-left: 2rem;
    font-size: 1.1rem;
}

.post-footer-footer img {
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 1rem;
}

.post-footer-footer-right {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.post-footer-footer-content {
    width: 100%; 
    height: auto;
    border-radius: 15px;
    background-color: rgb(231, 231, 231);
    display: flex;
    flex-direction: column;
    padding: 5px 10px 0px 10px;
    padding-bottom: 0;
}

.post-footer-footer-content img {
    width: 30%;
    height: auto;
    max-height: 200px;
    border-radius: 10px;
    margin-bottom: 0.5rem;
    margin-top: 0;
}

.black-line {
    margin-left: 2rem;
    margin-top: 0;
    margin-bottom: 10px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-content {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
}

.overlay-content p {
    margin-bottom: 1rem;
}

.overlay-content button {
    margin: 0 1rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.overlay-content button:hover {
    background: #ddd;
}


@media (max-width: 580px) {
.post-header {
    margin-left: 0.3rem;
}.post-content {
    margin-left: 0.3rem;
}.post-footer-bottom{
    margin-left: 0.5rem;
}
}@media (max-width: 450px) {
.post-footer-bottom{
    width: 100%;
}.post-footer-bottom-comment{
    width: 88%;
    margin-left: 0.2rem;
}.post-footer-top {
    display:flex ;
    margin-left: 0.7rem;
    padding-bottom: 0;
}.post-footer-middle {
    margin-left: 0rem;
}.post-footer-footer {
    display:flex ;
    margin-left: 0.6rem;
    font-size: 1.1rem;
}.black-line {
    margin-left: 0.7rem;
}
}