.container {
  width: 100%;
  padding: 0px 100px;
}

.Manager {

  hr {
    width: 100px;
    border: 2px solid #35973F;
    opacity: 1;
    margin-bottom: 10px;
    margin-top: 40px;
  }
}

.row-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px
}

.add-button {
  padding: 5px 30px;
  border: none;
  background-color: #ffffff;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 800;
  color: #35973F;
  border: 3px solid #35973F;
}

.title {
  font-size: 36px;
  font-weight: 700;
  color: #35973F;
  display: inline-block;
  // margin-bottom: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.table th,
.table td {
  padding: 12px;
  text-align: center;
}

.table th {
  background-color: #35973F;
  color: white;
  font-weight: bold;
  font-size: 18px;
}

.table tr:nth-child(even) {
  background-color: #ECFFEF;
}

.table tr:nth-child(odd) {
  background-color: white;
}

.table tr:hover {
  background-color: #e9e9e9;
  cursor: pointer;
}

.table td {
  border: 1px solid #ddd;
  height: 100px;
  font-size: 18px;
  font-weight: 600;
}

.table td.id, .table th.id {
  width: 120px;
}
.table td.id {
  font-size: 22px;
  font-weight: 700;
}

.table td.name {
  text-align: left;
}

.table td.province, .table th.province,  .table td.district, .table th.district {
  width: 200px;
}

.table td.joined, .table th.joined {
  width: 120px;
}
.table td.joined {
  font-size: 22px;
  font-weight: 700;
  color: #35973F;
}

.table td.pending, .table th.pending {
  width: 120px;
}
.table td.pending {
  font-size: 22px;
  font-weight: 700;
  color: #AD3D3D;
}

.table td.status, .table th.status {
  width: 200px;
}

button.status {
  padding: 10px 0px;
  border: none;
  border-radius: 30px;
  width: 160px;
  font-size: 18px;
  font-weight: 600;
  color: white;
}

button.edit {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #e8e129;
  font-size: 20px;
  text-align: center;
}

.status.dang-dien-ra {
  background-color: #35973F;
}

.status.sap-dien-ra {
  background-color: #1E266D;
}

.status.da-ket-thuc {
  background-color: #AD3D3D;
}

.centeredSpin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // Để đảm bảo Spin nằm giữa màn hình
  .ant-spin-dot {
    font-size: 40px; // Tăng kích thước Spin nếu cần
    .ant-spin-dot-item {
      background-color: #1cef09; // Đổi màu cho Spin
    }
  }
}

.custom-pagination {
  .ant-pagination-item {
    border-color: #35973F; // Đổi màu viền các nút
  }

  .ant-pagination-item a {
    color: #35973F; // Đổi màu chữ của các số trang
  }

  .ant-pagination-item-active {
    border-color: #35973F; // Đổi màu viền của trang hiện tại
    background-color: #35973F; // Đổi màu nền của trang hiện tại

    a {
      color: #fff; // Đổi màu chữ của trang hiện tại
    }
  }

  .ant-pagination-item-link {
    color: #35973F; // Đổi màu các nút điều hướng (prev, next)
    border-color: #35973F;
  }

  .ant-pagination-item-link:hover {
    border-color: #35973F; // Màu viền khi hover vào các nút điều hướng
    color: #35973F; // Màu chữ khi hover vào các nút điều hướng
  }
}
