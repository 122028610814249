.container {
    width: 100%;
    max-height: 30rem; /* Adjust this as needed, can also use a percentage value */
    background-color: rgb(255, 255, 255);
    display: flex;
    padding: 0;
    flex-direction: column;
    overflow-y: auto; 
    height: 100%;  

    input:focus {
        outline: none;
    }
}
.containergroup {
    width: 100%;
    height: 4rem;
    background-color: rgb(255, 255, 255);
    display: flex;
    padding: 0.4rem;
    cursor: pointer; /* Thêm con trỏ khi di chuột vào */
}

.containergroup:hover {
    background-color: rgb(212, 248, 213);
}
.groupavatar img {
    width: 3.3rem;
    height: 3.3rem;
    border-radius: 50%;
    margin-bottom: auto;
    object-fit: cover;
}.groupinfo {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
    margin-top: 0.2rem;
    width: calc(100% - 4rem);
}.groupinfo h6 {
    margin: 0;
    font-weight: revert;
    font-size: 20px;
    max-width: calc(100% - 20px); 
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap;
    margin-bottom: 0;
}.groupinfo span{
    margin: 0;
    font-weight: revert;
    max-width: 11rem; 
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap;
}