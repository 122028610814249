@font-face {
    font-family: 'RaleWay';
    src: url('../../../component/Fonts/Raleway/Raleway-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'Radio Canada';
    src: url('../../../component/Fonts/Radio_Canada/RadioCanada-VariableFont_wdth\,wght.ttf');
}


@font-face {
    font-family: 'Montserrat';
    src: url('../../../component/Fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
}


div.main {
    width: 100%;
    height: 950px;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    background-image: url("../../../Images/Quy/Top/anhnen.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}


div.main div.upper {
    width: 90%;
    height: 161.424px;
    flex-shrink: 0;

    margin-top: 81px;

    color: #094B17;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 45.5px */

    /* border: 1px solid red; */
}


div.main div.bottom {
    width: 100%;
    /* height: 159.263px; */
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}


div.main div.bottom img {
    width: 63px;
    height: 61.263px;
    flex-shrink: 0;
}


div.main div.bottom div.imgpaper {
    width: 100%;
    height: 200px;
    flex-shrink: 0;

    background-image: url("../../../Images/Quy/Top/paper2.png");
    background-size: cover;
    background-repeat: round;
}





/* Scrolling animation */
.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(100%);
    transition: all 1s;
}


.show {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
}



@media screen and (max-width: 1600px) {
    div.main {
        width: 100%;
        height: 850px;
    }
    
    div.main div.upper {
        width: 90%;
        height: 161.424px;
        margin-top: 81px;
        font-size: 35px;
        font-weight: 700;
    } 
    
    div.main div.bottom img {
        width: 63px;
        height: 61.263px;
    }
    
    div.main div.bottom div.imgpaper {
        width: 100%;
        height: 150px;
    }
    
}

@media screen and (max-width: 1199.8px) {
    div.main {
        width: 100%;
        height: 750px;
    }
}

@media screen and (max-width: 991.8px) {
    div.main {
        width: 100%;
        height: 650px;
    }

    div.main div.upper {
        width: 90%;
        height: 161.424px;
        margin-top: 81px;
        font-size: 26px;
    } 
}

@media screen and (max-width: 767.8px){
    div.main {
        width: 100%;
        height: 500px;
    }

    div.main div.upper {
        width: 90%;
        height: 161.424px;
        margin-top: 81px;
        font-size: 20px;
    } 

    div.main div.bottom div.imgpaper {
        width: 100%;
        height: 100px;
    }
}

@media screen and (max-width: 575.8px){
    div.main {
        width: 100%;
        height: 300px;
    }

    div.main div.upper {
        width: 90%;
        margin-top: 30px;
        font-size: 13px;
    } 

    div.main div.bottom div.imgpaper {
        width: 100%;
        height: 80px;
    }
}