.Campaign {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: rgba(0, 0, 0, 0.7) 2px 10px 15px;
  border-radius: 6px;

  &:hover .button{
    display: block;
    opacity: 1;
    transform: scale(1);
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.9);
    transition: background-color 1s; 
  }
}

.camp-id {
  position: absolute;
  display: inline-block;
  top: 20px;
  left: 20px;
  font-size: 36px;
  font-weight: 600;
  color: white;
  line-height: 100%;
}

.camp-type {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  padding: 3px 10px;
  font-size: 20px;
  font-weight: 500;
  color: white;
  background-color: #35973F;
  border-radius: 10px;
}

.Campaign .title {
  padding-top: 50%;
  padding-left: 20px;
  font-size: 36px;
  font-weight: 600;
  color: white;
}

.Campaign .desc {
  padding-left: 20px;
  padding-top: 10px;
  font-size: 28px;
  font-weight: 500;
  color: white;
  line-height: 120%;
}

.Campaign .button {
  position: absolute;
  padding: 10px 30px;
  font-size: 20px;
  font-weight: 700;
  color: white;
  background-color: #35973F;
  border-radius: 30px;
  border: none;
  top: calc(50% - 30px);
  left: calc(50% - 60px);
  // display: none;
  transform: scale(0.5);
  opacity: 0;
  transition: opacity 1s ease, transform 1s ease;
}

@media screen and (max-width: 1600px) {
  .Campaign {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: rgba(0, 0, 0, 0.7) 2px 10px 15px;

    &:hover .button {
      display: block;
      opacity: 1;
      transform: scale(1);
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.9);
      transition: background-color 1s;
    }
  }

  .camp-id {
    position: absolute;
    display: inline-block;
    top: 15px;
    left: 15px;
    font-size: 30px;
    font-weight: 600;
    color: white;
    line-height: 100%;
  }

  .camp-type {
    position: absolute;
    top: 15px;
    right: 15px;
    display: inline-block;
    padding: 3px 10px;
    font-size: 16px;
    font-weight: 500;
    color: white;
    background-color: #35973f;
    border-radius: 10px;
  }

  .Campaign .title {
    padding-top: 50%;
    padding-left: 20px;
    font-size: 28px;
    font-weight: 600;
    color: white;
  }

  .Campaign .desc {
    padding-left: 20px;
    padding-top: 10px;
    font-size: 24px;
    font-weight: 500;
    color: white;
    line-height: 120%;
  }

  .Campaign .button {
    position: absolute;
    padding: 10px 30px;
    font-size: 20px;
    font-weight: 700;
    color: white;
    background-color: #35973F;
    border-radius: 30px;
    border: none;
    top: calc(50% - 30px);
    left: calc(50% - 60px);
    // display: none;
    transform: scale(0.5);
    opacity: 0;
    transition: opacity 1s ease, transform 1s ease;
  }
}

@media screen and (max-width: 1500px) {
  .Campaign .desc {
    font-size: 20px;
  }
}

@media screen and (max-width: 1199.8px) {
  .camp-id {
    font-size: 24px;
  }

  .camp-type {
    font-size: 14px;
  }

  .Campaign .title {
    font-size: 22px;
    padding-top: 40%;
  }

  .Campaign .desc {
    font-size: 18px;
  }
}

@media screen and (max-width: 991.8px) {
  .camp-id {
    font-size: 20px;
  }

  .camp-type {
    font-size: 12px;
  }

  .Campaign .title {
    font-size: 20px;
    padding-top: 35%;
  }

  .Campaign .desc {
    font-size: 16px;
  }
}

@media screen and (max-width: 767.8px) {
  .camp-id {
    font-size: 24px;
  }

  .camp-type {
    font-size: 14px;
  }

  .Campaign .title {
    font-size: 22px;
    padding-top: 40%;
  }

  .Campaign .desc {
    font-size: 18px;
  }
}

@media screen and (max-width: 575.8px) {
  .camp-id {
    font-size: 36px;
  }

  .camp-type {
    font-size: 20px;
  }

  .Campaign .title {
    font-size: 30px;
    padding-top: 50%;
  }

  .Campaign .desc {
    font-size: 24px;
  }
}
