.Intro {
    width: 100%;
    height: calc(100vh - 60px);
}

.video-container {
    position: relative;
    width: 100%;
    background-color: black;
}

.video-container iframe {
    position: absolute;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    top: -60px;
    z-index: 1;
    background-color: black;
}

.skip-button {
    position: absolute;
    top: calc(50vh - 60px);
    right: 7vw;
    font-size: 16px;
    font-weight: 600;
    background: rgba(61, 60, 60, 0.5);
    color: white;
    border: none;
    border-radius: 30px;
    padding: 10px 30px;
    cursor: pointer;
    z-index: 2; /* Đảm bảo nút luôn ở trên video */
}

.slogan-container {
    width: 100%;
    height: 100%;
    background-image:linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../../Images/Intro/background_intro.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 30vh;
    text-align: center;
}


.slogan-container .slogan {
    font-size: 36px;
    font-weight: 600;
    color: white;
    text-align: center;
}

.slogan-container .slogan-text {
    font-size: 30px;
    font-weight: 600;
    color: rgb(72, 222, 13);
    text-align: center;
    z-index: 999;
    // animation: slideIn 2s forwards;
}

.join-button {
    font-size: 16px;
    font-weight: 700;
    background: rgba(177, 177, 177, 0.5);
    color: white;
    border: rgb(72, 222, 13) 2px solid;
    border-radius: 30px;
    padding: 10px 30px;
    cursor: pointer;
    z-index: 2; 
    margin-top: 30px;
}

@media screen and (max-width: 1500px) {
  
}

@media screen and (max-width: 1199.8px) {
    .slogan-container .slogan {
        font-size: 30px;
    }
    
    .slogan-container .slogan-text {
        font-size: 26px;
    }
}

@media screen and (max-width: 991.8px) {
    .slogan-container .slogan {
        font-size: 24px;
    }
    
    .slogan-container .slogan-text {
        font-size: 20px;
    }
}

@media screen and (max-width: 767.8px){
    .slogan-container .slogan {
        font-size: 18px;
    }
    
    .slogan-container .slogan-text {
        font-size: 16px;
    }
}

@media screen and (max-width: 575.8px){
    .slogan-container .slogan {
        font-size: 14px;
    }
    
    .slogan-container .slogan-text {
        font-size: 12px;
    }
}