.overView {
  margin-top: 40px;
}

@media screen and (max-width: 991.8px) {
  .row-overView {
    justify-content: center;
  }
}

@media screen and (max-width: 767.8px){
  
}

@media screen and (max-width: 575.8px){
  
}