body {
    background-color: #EEF4F0;
}

.container {
    width: 95%;
    height: 100%;
    height: auto;
}.containergroupuser {
    width: 100%;
    height: 60rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem;
}.containergroupuser input{
    width: 90%;
    height: auto;
    background-color: white;
    border-radius: 20px;
    padding: 0.2rem;
    border: 1px solid #35973F;
    padding-left: 0.8rem;
}.group {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    overflow-y: auto; /* Thêm dòng này để bật thanh cuộn dọc khi nội dung vượt quá chiều cao */
}.groupline {
    padding-top: 0.4rem;
    display: flex;
    width: 100%;
    margin-bottom: 0.5rem;
    border-radius: 10px;
}.groupline:hover {
    background-color: rgb(223, 223, 223);
}.groupline img {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 30%;
  object-fit: cover;
}.grouplineinfo {
    width: calc(100% - 75px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 0.3rem;
}

.campaignTitle, .campaignProvince {
    font-weight: 600;
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}.containerhead {
    width: 100%;
    height: auto;
    background-color: rgb(255, 255, 255);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
}.containerhead img{
    width: 95%;
    height: auto;
    max-height: 33rem;
    margin-left: auto;
    margin-right: auto;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

}.containerheadinfo {
    display: flex;
    margin-top: 1rem;
    margin-left: 2rem;
    height: auto;
    display: flex;
}.containerheadinfo1 {
    display: flex;
    flex-direction: column;
    flex: 1.3;
}.containerheadinfo1 span{
    font-weight: 500;
}.containerheadinfo2 {
    display: flex;
    margin-left: auto;
    margin-right: 4rem;
    margin-top: 0.5rem;

}.containerheadinfo2 button{
    width: auto;
    height: 2.5rem;
    padding: 0.5rem;
    background-color: #35973F;
    color: #ffffff;
    margin-left: 0.5rem;
    border-radius: 10px;
    border: 1px solid white;
    font-weight: 500;
    
}

.container1 {
    border-radius: 15px;
    width: 100%;
    margin-top: 0.5rem;
    display: flex;
    background-color: #ffffff;
    padding: 0.3rem;
    padding-left: 1rem;
}.container1 img{
    width: 2.7rem;
    height: 2.7em;
    border-radius:50% ;
    object-fit: cover;
}.container1 button{
    width: 90%;
    height: 3rem;
    border-radius: 25px;
    border: 1px solid rgb(182, 182, 182);
    margin-left: 0.3rem;
    background-color: #ffffff;
}



.container2 {
    margin-top: 0.5rem;
    width: 100%;
    height: auto;
    border-radius: 20px;
    background-color: #EEF4F0;
    padding-bottom: 2rem;
    
}

.container3 {
    margin-top: 0.5rem;
    width: 100%;
    height: auto;
    border-radius: 20px;
    background-color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}.container3-info {
    width: 100%;
    height: auto;
    display: flex;
}.container3 img{
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
}.container3-content {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0.3rem;
    margin-left: 0.7rem;
}


.container4 {
    margin-top: 1rem;
    width: 100%;
    height: auto;
    border-radius: 20px;
    background-color: white;
}

.black-line {
    border: none;
    border-top: 1px solid black;
}.sticky {
    position: -webkit-sticky; 
    position: sticky;
    top: 4rem; /* Khoảng cách từ đỉnh viewport */
    z-index: 1; /* Đảm bảo nó nằm trên các phần tử khác */
}.hidden {
    display: none;
}.sk {
    width: 20rem !important;
    height: auto !important;
    margin-left: 1rem !important;
}
@media (max-width : 1100px) {
.containerheadinfo {
    flex-direction: column;
}.containerheadinfo2 {
    margin-right: auto;
    margin-left: 0;
}
}
@media (max-width : 800px) {
.sticky-search-bar input{
    width: 92%;
}.mobile {
    display: none;
}.container {
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    
}
}@media (max-width : 770px) {
.sticky-search-bar input{
    width: 92%;
}
}@media (max-width : 600px) {
.sticky-search-bar input{
    width: 90%;
}
}