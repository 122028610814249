.container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
}

.heading {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
    text-align : center;
}
.subheading {
    font-size: 1.4rem;
    font-weight: bold;
    color: #555;
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
}

.info-container {
    margin-bottom: 20px;
}

.info-item {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 10px;
    line-height: 1.5;
    font-weight: 600;
}

.info-item strong {
    color: #222;
}

.admin-image {
    margin-top: 10px;
    max-width: 150px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
