body {
    background-color: #EEF4F0;
}

.container {
    width: 100%;
    height: 100%;
    height: auto;
    padding-left: 2rem;
    padding-right: 2rem;
}

.container1 {
    border-radius: 25px;
    width: 100%;
    margin-top: 1rem;
}

.sticky-search-bar {
    background-color: #f6f9f7; 
    border : none;
    width: 100%;
}.sticky-search-bar input{
    border-radius: 20px;
    width: 95%;
    border: 1px solid transparent; 
    margin-right: 0.3rem;
    margin-left: 0.1rem;
    padding-left: 0.5rem; 
    padding-right: 0.3rem;
    transition: border-color 0.3s; 
    height: 2.5rem;
}.sticky-search-bar input:focus {
    outline: none; 
    border-color: #37be54; 
}.sticky-search-bar i {
    font-size: larger;
    color: rgb(34, 105, 29);
}

.container2 {
    margin-top: 2rem;
    width: 100%;
    height: auto;
    border-radius: 20px;
    background-color: #EEF4F0;
    padding-bottom: 2rem;
    
}

.container3 {
    margin-top: 2rem;
    width: 100%;
    height: auto;
    border-radius: 20px;
    background-color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}.container3-info {
    width: 100%;
    height: auto;
    display: flex;
}.container3-info:hover {
    margin-top : 0.3rem;
    background-color: rgb(212, 212, 212);
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
}.container3 img{
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    object-fit: cover;
}.container3-content {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 0.3rem;
    margin-left: 0.7rem;
}


.container4 {
    margin-top: 5rem;
    width: 100%;
    height: auto;
    border-radius: 20px;
    background-color: white;
}

.black-line {
    border: none;
    border-top: 1px solid black;
}

@media (max-width : 1050px) {
.sticky-search-bar input{
    width: 92%;
}
}@media (max-width : 770px) {
.sticky-search-bar input{
    width: 92%;
}
}@media (max-width : 600px) {
.sticky-search-bar input{
    width: 90%;
}.container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
}









