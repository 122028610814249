.map {
  position: relative;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), 
              url("https://i0.wp.com/www.goexplorevietnam.com/wp-content/uploads/2018/07/Yen-island-Nha-Trang-Vietnam.jpg?fit=1296%2C864&ssl=1");
  background-size: cover;
  background-position: center right;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
}

//https://h3qvn.com/hinh-phong-canh-dep-viet-nam/imager_18_27936_700.jpg

.mapVietNam {
  width: 100%;
  height: auto;
  max-width: 1000px; /* Giới hạn kích thước tối đa nếu cần */
  display: block;
  margin: auto;
}

@media screen and (max-width: 1500px) {
  
}

@media screen and (max-width: 1199.8px) {

}

@media screen and (max-width: 991.8px) {
  
}

@media screen and (max-width: 767.8px){
  
}

@media screen and (max-width: 575.8px){
  
}


