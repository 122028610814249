@font-face {
    font-family: 'Radio Canada';
    src: url('../../../component/Fonts/Radio_Canada/RadioCanada-VariableFont_wdth\,wght.ttf');
}


@font-face {
    font-family: 'Montserrat';
    src: url('../../../component/Fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
}


div.main {
    width: 100%;
    padding: 80px 200px;
    flex-shrink: 0;
    background: #1B9F56;
    display: flex;
    justify-content: space-between;
    gap: 52px;
    flex-wrap: wrap;
}


div.main div.div_left {
    width: 50%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    /* border: 1px solid blue; */
}


div.main div.div_left div.top {
    width: 100%;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    /* border: 1px solid red; */
}


div.main div.div_left div.top h2 {
    width: 100%; 
    margin-bottom: 30px;
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 61.74px */
}


div.main div.div_left div.top p {
    width: 100%; 

    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 31.9px */
    text-align: justify;
}


div.main div.div_left div.bot {
    width: 100%;
    flex-shrink: 0;

    display: flex;
    flex-wrap: wrap;

    /* border: 1px solid red; */
}


div.main div.div_left div.bot p.p1 {
    width: 100%;

    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 41.6px */
    margin-bottom: 20px;
}


div.main div.div_right {
    width: 40%;
    flex-shrink: 0;

    display: flex;
    flex-wrap: wrap;

    /* border: 1px solid red; */
}


div.main div.div_right img {
    width: 100%;
    height: 90%;
    flex-shrink: 0;
    margin-bottom: 0px;

    border-radius: 10px;
    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.25);
}


div.main div.div_right p {
    width: 100%;

    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 20.8px */
    margin-top: 5px;
}





/* Scrolling animation */
.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(100%);
    transition: all 1s;
}


.show {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
}


div.main div.div_left div.top p {
    transition-delay: 200ms;
}


@media screen and (max-width: 1600px) {
    div.main {
        padding: 50px 100px;
    }
    
    div.main div.div_left {
        width: 50%;
    }
    
    div.main div.div_left div.top {
        width: 100%;
    }
    
    div.main div.div_left div.top h2 {
        width: 100%; 
        margin-bottom: 20px;
        font-size: 32px;
    }
    
    div.main div.div_left div.top p {
        width: 100%; 
        font-size: 20px;
    }
    
    div.main div.div_left div.bot {
        width: 100%;
    }
    
    div.main div.div_left div.bot p.p1 {
        width: 100%;
        font-size: 28px;
    }
    
    div.main div.div_right {
        width: 40%;
    }
    
    div.main div.div_right img {
        width: 100%;
        height: 90%;
    }
    
    div.main div.div_right p {
        width: 100%;
        font-size: 14px;
    }
    
}

@media screen and (max-width: 1199.8px) {
    div.main {
        padding: 50px 100px;
        padding-top: 80px;
    }
    
    div.main div.div_left {
        width: 50%;
    }
    
    div.main div.div_left div.top {
        width: 100%;
    }
    
    div.main div.div_left div.top h2 {
        width: 100%; 
        margin-bottom: 20px;
        font-size: 28px;
    }
    
    div.main div.div_left div.top p {
        width: 100%; 
        font-size: 16px;
    }
    
    div.main div.div_left div.bot {
        width: 100%;
    }
    
    div.main div.div_left div.bot p.p1 {
        width: 100%;
        font-size: 26px;
    }
    
    div.main div.div_right {
        width: 40%;
    }
    
    div.main div.div_right img {
        width: 100%;
        height: 90%;
    }
    
    div.main div.div_right p {
        width: 100%;
        font-size: 14px;
    }
}

@media screen and (max-width: 991.8px) {
    div.main {
        padding: 50px 100px;
        padding-top: 80px;
        justify-content: center;
    }
    
    div.main div.div_left {
        width: 100%;
    }
    
    div.main div.div_left div.top {
        width: 100%;
    }
    
    div.main div.div_left div.top h2 {
        width: 100%; 
        margin-bottom: 20px;
        font-size: 28px;
    }
    
    div.main div.div_left div.top p {
        width: 100%; 
        font-size: 20px;
    }
    
    div.main div.div_left div.bot {
        width: 100%;
        margin-top: 50px;
    }
    
    div.main div.div_left div.bot p.p1 {
        width: 100%;
        font-size: 26px;
    }
    
    div.main div.div_right {
        width: 80%;
    }
    
    div.main div.div_right img {
        width: 100%;
        height: 90%;
    }
    
    div.main div.div_right p {
        width: 100%;
        font-size: 14px;
    }
}

@media screen and (max-width: 767.8px){
    div.main {
        padding: 30px 50px;
        padding: 50px;
        padding-top: 80px;
        justify-content: center;
    }
    
    div.main div.div_left {
        width: 100%;
    }
    
    div.main div.div_left div.top {
        width: 100%;
    }
    
    div.main div.div_left div.top h2 {
        width: 100%; 
        margin-bottom: 20px;
        font-size: 32px;
    }
    
    div.main div.div_left div.top p {
        width: 100%; 
        font-size: 18px;
    }
    
    div.main div.div_left div.bot {
        width: 100%;
    }
    
    div.main div.div_left div.bot p.p1 {
        width: 100%;
        font-size: 30px;
    }
    
    div.main div.div_right {
        width: 80%;
    }
    
    div.main div.div_right img {
        width: 100%;
        height: 90%;
    }
    
    div.main div.div_right p {
        width: 100%;
        font-size: 14px;
    }
}

@media screen and (max-width: 575.8px){
    div.main {
        padding: 50px 20px;
        padding-bottom: 80px;
        justify-content: center;
    }
    
    div.main div.div_left {
        width: 100%;
    }
    
    div.main div.div_left div.top {
        width: 100%;
    }
    
    div.main div.div_left div.top h2 {
        width: 100%; 
        margin-bottom: 20px;
        font-size: 22px;
    }
    
    div.main div.div_left div.top p {
        width: 100%; 
        font-size: 14px;
    }
    
    div.main div.div_left div.bot {
        width: 100%;
        margin-top: 20px;
    }
    
    div.main div.div_left div.bot p.p1 {
        width: 100%;
        font-size: 20px;
    }
    
    div.main div.div_right {
        width: 80%;
    }
    
    div.main div.div_right img {
        width: 100%;
        height: 90%;
    }
    
    div.main div.div_right p {
        width: 100%;
        font-size: 10px;
        margin-top: 5px;
    }
}