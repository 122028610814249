body {
    background-color: #EEF4F0;
}

.container {
    width: 95%;
    height: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.container1 {
    border-radius: 25px;
    width: 100%;
    margin-top: 2rem;
}

.sticky-search-bar {
    background-color: #f6f9f7;
    border: none;
    width: 100%;
}

.sticky-search-bar input {
    border-radius: 20px;
    width: 95%;
    border: 1px solid transparent;
    margin-right: 0.3rem;
    margin-left: 0.1rem;
    padding-left: 20px;
    padding-right: 0.3rem;
    transition: border-color 0.3s;
    height: 2.5rem;
}

.sticky-search-bar input:focus {
    outline: none;
    border-color: #35973F;
}

.sticky-search-bar i {
    font-size: larger;
    color: #35973F;
}

.container2 {
    margin-top: 0.5rem;
    width: 100%;
    height: auto;
    border-radius: 20px;
    background-color: #EEF4F0;
    padding-bottom: 2rem;

}

.container3 {
    margin-top: 2rem;
    width: 100%;
    height: auto;
    border-radius: 20px;
    background-color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.container3-info {
    padding-bottom:0;
    width: 100%;
    height: auto;
    display: flex;
    cursor : pointer;
}.container3-info:hover {
    background-color: #dedede;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.container3 img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
}

.container3-content {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 0.3rem;
    margin-left: 0.7rem;
    max-width: 100%;
    /* Ensure the max-width is applied */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
}

.ellipsis-text h6,
.ellipsis-text p {
    font-weight: 600;
    /* Reset font-weight for h6 */
    font-size: 1.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    /* Ensure the max-width is applied to child elements */
}


.container4 {
    margin-top: 1rem;
    width: 100%;
    height: auto;
    border-radius: 20px;
    background-color: white;
}

.black-line {
    border: none;
    border-top: 1px solid black;
}

.sticky {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 4.4rem;
    /* Khoảng cách từ đỉnh viewport */
    z-index: 1;
    /* Đảm bảo nó nằm trên các phần tử khác */
}

@media (max-width : 1600px) {
    body {
        background-color: #EEF4F0;
    }

    .container {
        width: 95%;
        height: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }

    .container1 {
        border-radius: 25px;
        width: 100%;
        margin-top: 2rem;
    }

    .sticky-search-bar {
        background-color: #f6f9f7;
        border: none;
        width: 100%;
    }

    .sticky-search-bar input {
        border-radius: 20px;
        width: 95%;
        border: 1px solid transparent;
        margin-right: 0.3rem;
        margin-left: 0.1rem;
        padding-left: 20px;
        padding-right: 0.3rem;
        transition: border-color 0.3s;
        height: 2.5rem;
    }

    .sticky-search-bar input:focus {
        outline: none;
        border-color: #35973F;
    }

    .sticky-search-bar i {
        font-size: larger;
        color: #35973F;
    }

    .container2 {
        margin-top: 0.5rem;
        width: 100%;
        height: auto;
        border-radius: 20px;
        background-color: #EEF4F0;
        padding-bottom: 2rem;

    }

    .container3 {
        margin-top: 5rem;
        width: 100%;
        height: auto;
        border-radius: 20px;
        background-color: white;
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: flex;
        flex-direction: column;
        padding: 1rem;
    }

    .container3-info {
        width: 100%;
        height: auto;
        display: flex;
    }

    .container3 img {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        object-fit: cover;
    }

    .container3-content {
        width: 80%;
        display: flex;
        flex-direction: column;
        margin-top: 0.3rem;
        margin-left: 0.7rem;
        max-width: 100%;
        /* Ensure the max-width is applied */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ellipsis-text h6,
    .ellipsis-text p {
        font-weight: 600;
        /* Reset font-weight for h6 */
        font-size: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        /* Ensure the max-width is applied to child elements */
    }


    .container4 {
        margin-top: 1rem;
        width: 100%;
        height: auto;
        border-radius: 20px;
        background-color: white;
    }

    .black-line {
        border: none;
        border-top: 1px solid black;
    }

    .sticky {
        position: -webkit-sticky;
        /* Safari */
        position: sticky;
        top: 4.4rem;
        /* Khoảng cách từ đỉnh viewport */
        z-index: 1;
        /* Đảm bảo nó nằm trên các phần tử khác */
    }

}

@media (max-width : 1050px) {
    .sticky-search-bar input {
        width: 92%;
    }

    .container3 {
        display: none;
    }

    .sticky {
        display: none;
    }
}

@media (max-width : 770px) {
    .sticky-search-bar input {
        width: 92%;
        
    }.sticky-search-bar {
        margin-top: 0.7rem;
    }
}

@media (max-width : 600px) {
    .sticky-search-bar input {
        width: 90%;
    }
}