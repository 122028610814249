* {
    margin: 0px;
    padding: 0px;
}

/* global font */
* {
    font-family: Montserrat;
}

/* NAVIGATION BAR */
header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    height: 60px;
    /* border-bottom: 1.5px solid rgb(62, 158, 45); */
    background: linear-gradient(to right, rgb(29, 96, 42), rgb(39, 154, 83));
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px;
}

header .logo img {
    width: auto;
    height: 2.9rem;
}

header .logo img.logo1 {
    display: block;
    padding-left: 10px;
    margin: 20px;
}

header .logo img.logo2 {
    display: none;
}

header .links {
    display: flex;
    align-items: center;
    margin-right: 1rem;
}

header .links nav {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

header .links nav a {
    margin: 0;
    padding: 0 1rem;
    cursor: pointer;
    text-decoration: none;
    color: white;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
}
header .links nav i {
    margin-left: 1px;
    font-size: 14px;
}

header .links nav a:hover {
    color: #b8e994;
}

header .bar {
    display: none;
    margin-right: 1rem;
    color: aliceblue;
    text-align: right;
}

header .overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.603);
    z-index: 9998; 
}

header .overlay.active {
    display: block;
}

header .sidebar {
    position: fixed;
    top: 0;
    right: -100%; 
    width: 20%;
    height: 100%;
    background: linear-gradient(to top, rgb(37, 79, 46), rgb(53, 189, 105));
    transition: right 0.3s ease-in-out; 
    z-index: 9999;
}

header .sidebar.open {
    right: 0; 
}

header .sidebar-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1.5rem;
    width: 100%;
}

header .sidebar-content a {
    text-align: right;
    text-decoration: none;
    font-size: 1.2rem;
    color: aliceblue;
    margin-top: 1.5rem;
    margin-right: 0;
}

header .sidebar-content a:hover {
    color: #b8e994;
}

header .sidebar-content i {
    font-size: smaller;
}

* {
    box-sizing: border-box;
}

header .list-navbar {
    display: flex;
    flex-direction: column;
    gap: 5px; 
    padding: 20px;
    float: right;
}

header .list-navbar a {
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
}

header .list-navbar a:hover {
    color: #b8e994;
}

.dropdown-toggle {
    cursor: pointer;
    text-decoration: none;
    color: white;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .dropdown-menu {
    display: none; 
    position: absolute;
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(3, 3, 3, 0.3);
    z-index: 1;
    border-radius: 4px;
    width: 205px;
    right: 15px;
    top: 60px;
    &:focus-within {
        display: none;
    }
  }
  
  .dropdown-menu .dropdown-item {
    color: black;
    padding: 8px 15px;
    text-decoration: none;
    display: block;
    font-size: 14px;
    text-align: center;
  }
  
  .dropdown-menu .dropdown-item:hover {
    background-color: #ddd;
    color: black;
  }
  
  .nav-item.open .dropdown-menu {
    display: block; /* Hiển thị dropdown menu khi .nav-item có lớp .open */
  }

  header .mobile{
    display: none;
}

  @media (max-width: 1380px) {
    header .links nav a, header .links nav .nav-item {
        display: none;
    }

    header .bar  {
        display: block;
    }

    header .sidebar {
        width: 30%;
    }
}
@media (max-width: 800px) {
    header .sidebar {
        width: 40%;
    }
}

@media (max-width: 630px) {
    header {
        height: 60px;
    }

    header .logo img.logo1 {
        display: none;
    }

    header .logo img.logo2 {
        display: block;
    }

    header .logo img {
        width: auto;
        height: 3.5rem;
        margin-left: 14rem;
    }

    header .sidebar {
        width: 50%;
    }
}

/* @media (max-width: 560px) {
    header .logo img {
        margin-left: 8rem;
    }

    header .sidebar {
        width: 60%;
    }
}
@media (max-width: 400px) {
    header .sidebar {
        width: 80%;
    }
}

@media (max-width: 320px) {
    header .logo img {
        margin-left: 5rem;
    } 
    header .sidebar {
        width: 95%;
    }
} */

 @media (max-width: 560px) {
    header .bar {
        display: none;
    }

    header .logo img.logo2 {
        display: none;
    }

    header .mobile{
        display: block;
        width: 100%;
    }

    header .mobile .mobileNav{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    header .mobile .mobileNav i {
        font-size: 1.5rem;
        color: white;
    }

    header .mobile .bar {
        display: inline-block;
    }

    header .sidebar {
        width: 65%;
    }

    header .sidebar-content {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 10px;
        padding-left: 0px;
        padding-top: 20px;
        width: 100%;
    }
    
    header .sidebar-content a {
        text-align: right;
        text-decoration: none;
        font-size: 18px;
        font-weight: 600;
        color: aliceblue;
        margin-top: 1.5rem;
        margin-right: 0;
    }
    
    header .sidebar-content a:hover {
        color: #b8e994;
    }

    header .list-navbar {
        display: flex;
        flex-direction: column;
        gap: 5px; 
        padding: 20px;
        padding-top: 0px;
        float: right;
    }
    

}



/* END NAVIGATION BAR */