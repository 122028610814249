@font-face {
    font-family: 'RaleWay';
    src: url('../../../component/Fonts/Raleway/Raleway-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'Radio Canada';
    src: url('../../../component/Fonts/Radio_Canada/RadioCanada-VariableFont_wdth\,wght.ttf');
}


@font-face {
    font-family: 'Montserrat';
    src: url('../../../component/Fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
}


div.main {
    width: 100%;
    /* height: 800px; */
    flex-shrink: 0;
    padding: 30px 200px 80px 200px;

    background-color: white;

    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 70px;
}


div.main div.div_left {
    width: 55%;
    /* height: 633px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 5%;

    /* border: 1px solid red; */
}


div.main div.div_left div.nenbg {
    width: 70%;
    height: 350px;
    margin-top: 160px;
    margin-left: -65px;

    background: rgba(51, 255, 0, 0.25);
    filter: blur(250px);

    position: absolute;

    z-index: 1;

    /* border: 1px solid red; */
}


div.main div.div_left img.anh1 {
    width: 100%;
    height: 347px;
    margin-bottom: 20px;

    border-radius: 10px;
    object-fit: cover;

    z-index: 2;
}


div.main div.div_left div.imgbot {
    width: 100%;
    height: 263px;
    display: flex;
    justify-content: space-between;
    z-index: 2;
}


div.main div.div_left div.imgbot img.anh2 {
    width: calc(50% - 10px);
    height: 100%;
    border-radius: 10px; 
    object-fit: cover;
}


div.main div.div_left div.imgbot img.anh3 {
    width: calc(50% - 10px);
    height: 100%;
    object-fit: cover;

    border-radius: 10px; 
}


div.main div.div_right {
    width: 40%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

    z-index: 2;

    /* border: 1px solid red; */
}


div.main div.div_right h2.h2 {
    width: 100%; 
    margin-bottom: 50px;

    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 58.5px */
    text-align: justify;
}


div.main div.div_right p.p1 {
    width: 100%; 

    margin-top: -20px;
    margin-bottom: 30px;
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 147%; /* 27.93px */
    text-align: justify;
}


div.main div.div_right div.stats1 {
    width: 100%;

    margin-top: -5px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


div.main div.div_right div.stats1 hr {
    width: 100%;
    height: 2px;

    margin-top: 0px;

    background: rgb(0,0,0);
}


div.main div.div_right div.stats1 p {
    width: 100%; 

    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 147%; /* 30.87px */
}


div.main div.div_right div.stats2 {
    width: 100%;

    display: flex;
    justify-content: space-between;
}


div.main div.div_right div.stats2 div.mini {
    width: 33%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


div.main div.div_right div.stats2 div.mini h2 {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
}


div.main div.div_right div.stats2 div.mini p {
    color: #616161;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 147%; /* 24.99px */
}



/* Scrolling animation */
.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(100%);
    transition: all 1s;
}


.show {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
}


div.main div.div_right p {
    transition-delay: 200ms;
}


div.main div.div_right div.stats1 p {
    transition-delay: 200ms;
}


div.main div.div_right div.stats1 hr:nth-child(2) {
    transition-delay: 400ms;
}


div.main div.div_right div.stats2 div.mini:nth-child(2) {
    transition-delay: 200ms;
}


div.main div.div_right div.stats2 div.mini:nth-child(3) {
    transition-delay: 400ms;
}




@media screen and (max-width: 1600px) {
    div.main {
        width: 100%;
        padding: 30px 100px 80px 100px;
    }
    
    div.main div.div_left {
        width: 55%;
        margin-right: 5%;
    }
    
    div.main div.div_left img.anh1 {
        width: 100%;
        height: 250px;
        margin-bottom: 20px;
    }
    
    div.main div.div_left div.imgbot {
        width: 100%;
        height: 200px;
    }
    
    div.main div.div_left div.imgbot img.anh2 {
        width: calc(50% - 10px);
        height: 100%;
    }

    div.main div.div_left div.imgbot img.anh3 {
        width: calc(50% - 10px);
        height: 100%;
    }
    
    div.main div.div_right {
        width: 40%;
    }
    
    div.main div.div_right h2.h2 {
        width: 100%; 
        margin-bottom: 30px;
        font-size: 35px;
    }
    
    div.main div.div_right p.p1 {
        width: 100%; 
        margin-top: -20px;
        margin-bottom: 30px;
        font-size: 16px;
        font-weight: 500;
    }
    
    div.main div.div_right div.stats1 {
        width: 100%;
        flex-shrink: 0;
        margin-top: -5px;
    }
    
    div.main div.div_right div.stats1 hr {
        width: 100%;
        height: 2px;
        margin-top: 0px;
    }
    
    div.main div.div_right div.stats1 p {
        font-size: 21px;
    }
    
    div.main div.div_right div.stats2 {
        width: 100%;
    }
    
    div.main div.div_right div.stats2 div.mini {
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center
    }
    
    div.main div.div_right div.stats2 div.mini h2 {
        font-size: 50px;
    }
    
    div.main div.div_right div.stats2 div.mini p {
        font-size: 12px;
    }
    
    
}

@media screen and (max-width: 1199.8px) {
    div.main {
        width: 100%;
        padding: 0px 100px 80px 100px;
        flex-wrap: wrap;
    }
    
    div.main div.div_left {
        width: 100%;
        margin-right: 0%;
    }
    
    div.main div.div_left img.anh1 {
        width: 100%;
        height: 250px;
        margin-bottom: 20px;
    }
    
    div.main div.div_left div.imgbot {
        width: 100%;
        height: 200px;
    }
    
    div.main div.div_left div.imgbot img.anh2 {
        width: calc(50% - 10px);
        height: 100%;
    }

    div.main div.div_left div.imgbot img.anh3 {
        width: calc(50% - 10px);
        height: 100%;
    }
    
    div.main div.div_right {
        width: 100%;

    }
    
    div.main div.div_right h2.h2 {
        width: 100%; 
        margin-bottom: 30px;
        font-size: 30px;
    }
    
    div.main div.div_right p.p1 {
        width: 100%; 
        margin-top: -20px;
        margin-bottom: 30px;
        font-size: 20px;
    }
    
    div.main div.div_right div.stats1 {
        width: 100%;
        margin-top: -5px;
    }
    
    div.main div.div_right div.stats1 hr {
        width: 100%;
        height: 2px;
        margin-top: 0px;
    }
    
    div.main div.div_right div.stats1 p {
        font-size: 24px;
    }
    
    div.main div.div_right div.stats2 {
        width: 100%;
    }
    
    div.main div.div_right div.stats2 div.mini {
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    div.main div.div_right div.stats2 div.mini h2 {
        font-size: 60px;
        line-height: 100%;
    }
    
    div.main div.div_right div.stats2 div.mini p {
        font-size: 20px;
    }
}

@media screen and (max-width: 991.8px) {
    div.main div.div_right div.stats2 div.mini p {
        font-size: 16px;
    }
}

@media screen and (max-width: 767.8px){
    div.main {
        width: 100%;
        padding: 50px 50px;
        flex-wrap: wrap;
    }
    
    div.main div.div_left {
        width: 100%;
        margin-right: 0%;
    }
    
    div.main div.div_left img.anh1 {
        width: 100%;
        height: 150px;
        margin-bottom: 20px;
    }
    
    div.main div.div_left div.imgbot {
        width: 100%;
        height: 150px;
    }
    
    div.main div.div_left div.imgbot img.anh2 {
        width: calc(50% - 10px);
        height: 100%;
    }

    div.main div.div_left div.imgbot img.anh3 {
        width: calc(50% - 10px);
        height: 100%;
    }
    
    div.main div.div_right {
        width: 100%;

    }
    
    div.main div.div_right h2.h2 {
        width: 100%; 
        margin-bottom: 30px;
        font-size: 28px;
    }
    
    div.main div.div_right p.p1 {
        width: 100%; 
        margin-top: -20px;
        margin-bottom: 30px;
        font-size: 18px;
    }
    
    div.main div.div_right div.stats1 {
        width: 100%;
        margin-top: -5px;
    }
    
    div.main div.div_right div.stats1 hr {
        width: 100%;
        height: 2px;
        margin-top: 0px;
    }
    
    div.main div.div_right div.stats1 p {
        font-size: 24px;
    }
    
    div.main div.div_right div.stats2 {
        width: 100%;
    }
    
    div.main div.div_right div.stats2 div.mini {
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    div.main div.div_right div.stats2 div.mini h2 {
        font-size: 60px;
        line-height: 100%;
    }
    
    div.main div.div_right div.stats2 div.mini p {
        font-size: 14px;
    }
}

@media screen and (max-width: 575.8px){
    div.main {
        width: 100%;
        padding: 30px 20px;
        flex-wrap: wrap;
    }
    
    div.main div.div_left {
        width: 100%;
        margin-right: 0%;
    }
    
    div.main div.div_left img.anh1 {
        width: 100%;
        height: 120px;
        margin-bottom: 20px;
    }
    
    div.main div.div_left div.imgbot {
        width: 100%;
        height: 120px;
    }
    
    div.main div.div_left div.imgbot img.anh2 {
        width: calc(50% - 10px);
        height: 100%;
    }

    div.main div.div_left div.imgbot img.anh3 {
        width: calc(50% - 10px);
        height: 100%;
    }
    
    div.main div.div_right {
        width: 100%;
        margin-top: 0px;
    }
    
    div.main div.div_right h2.h2 {
        width: 100%; 
        margin-bottom: 30px;
        font-size: 22px;
    }
    
    div.main div.div_right p.p1 {
        width: 100%; 
        margin-top: -20px;
        margin-bottom: 30px;
        font-size: 14px;
    }
    
    div.main div.div_right div.stats1 {
        width: 100%;
        margin-top: -10px;
    }
    
    div.main div.div_right div.stats1 hr {
        width: 100%;
        height: 2px;
        margin-top: 0px;
    }
    
    div.main div.div_right div.stats1 p {
        font-size: 18px;
    }
    
    div.main div.div_right div.stats2 {
        width: 100%;
    }
    
    div.main div.div_right div.stats2 div.mini {
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    div.main div.div_right div.stats2 div.mini h2 {
        font-size: 40px;
        line-height: 100%;
        margin-top: 0;
    }
    
    div.main div.div_right div.stats2 div.mini p {
        font-size: 10px;
    }
}