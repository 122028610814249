div.main {
  flex-shrink: 0;
  background: #1B9F56;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-top: 50px;
}


div.main div.top {
  width: 80%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}


div.main div.top h2 {
  color: #FFF;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}


div.main div.top p {
  color: #F0EFEF;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  /* 33px */
}

.donate {
  width: 100%;
  padding: 0 300px;
}

.quy {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 20px;
}

.quy .title {
  text-align: center;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
}

.quy {
  label {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 15px;
  }

  form {
    background-color: white;
    padding: 50px 50px;
    border-radius: 15px;
  }

  input {
    width: 100%;
    border: 1px solid #c9c9c9;
    border-radius: 5px;
    padding-left: 10px;
    font-size: 18px;
    font-weight: 500;
    color: black;

    &:focus-visible {
      outline: none;
      border: 1px solid #5fa967;
      box-shadow: none;
    }
  }

  .btn-donate {
    padding: 10px 20px;
    border: 2px solid #1B9F56;
    color: #1B9F56;
    border-radius: 5px;
    font-size: 24px;
    font-weight: 700;
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
    background-color: white;

    &:hover {
      background-color: #1B9F56;
      color: white;
    }
  }

  .input-donate {
    padding: 10px 20px !important;
    border: 2px solid #1B9F56 !important;
    color: #1B9F56 !important;
    border-radius: 5px !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    margin-right: 0 !important;
    margin-bottom: 20px !important;
    width: 100% !important;
    text-align: center !important;
  }

  .money-donate {
    padding: 10px 20px !important;
    border: 2px solid #1B9F56 !important;
    color: #1B9F56 !important;
    border-radius: 5px !important;
    font-size: 36px !important;
    font-weight: 700 !important;
    margin-right: 0 !important;
    margin-bottom: 20px !important;
    width: 100% !important;
    text-align: center !important;
  }
}

.button {
  text-align: center;
}

.quy .qr-code {
  width: 50%;
  height: 600px;
  /* background-image:linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../../../Images/Quy/Donate/background_intro.webp"); */
  background-image:url("../../../Images/Quy/Donate/background_intro.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quy .img-qr {
  height: 80%;
  width: auto;
  text-align: center;

  img {
    height: 100%;
    object-fit: contain;
    border-radius: 15px;
  }
}

.quy .transfer-form {
  width: 40%;
}


.modal,
.modal-history {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  z-index: 1000;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

#signUpForm {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 50%;
  position: relative;
}
#signUpForm .form-header {
  gap: 5px;
  text-align: center;
  font-size: 1.2em;
}
#signUpForm .form-header .stepIndicator {
  position: relative;
  flex: 1;
  padding-bottom: 30px;
}
#signUpForm .form-header .stepIndicator.active {
  font-weight: 600;
}
#signUpForm .form-header .stepIndicator.finish {
  font-weight: 600;
  color: #1B9F56;
}
#signUpForm .form-header .stepIndicator::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 9;
  width: 20px;
  height: 20px;
  background-color: #d5efed;
  border-radius: 50%;
  border: 3px solid #ecf5f4;
}
#signUpForm .form-header .stepIndicator.active::before {
  background-color: #1B9F56;
  border: 3px solid #d5f9f6;
}
#signUpForm .form-header .stepIndicator.finish::before {
  background-color: #1B9F56;
  border: 3px solid #e3e3e3;
}
#signUpForm .form-header .stepIndicator::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 8px;
  width: 100%;
  height: 3px;
  background-color: #f3f3f3;
}
#signUpForm .form-header .stepIndicator.active::after {
  background-color: #e3e3e3;
}
#signUpForm .form-header .stepIndicator.finish::after {
  background-color: #1B9F56;
}
#signUpForm .form-header .stepIndicator:last-child:after {
  display: none;
}
#signUpForm input {
  padding: 15px 20px;
  width: 100%;
  font-size: 1.4em;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}
#signUpForm input, #signUpForm textarea:focus {
  border: 1px solid #1B9F56;
  outline: 0;
}
#signUpForm input.invalid, #signUpForm textarea.invalid {
  border: 1px solid #ffaba5;
}
#signUpForm .step {
display: none;
}

#signUpForm .title {
  font-size: 24px;
}

#signUpForm .content {
  font-size: 22px;
}

#signUpForm .form-footer{
  width: calc(100% - 80px);
  overflow:auto;
  gap: 20px;
  position: absolute;
  bottom: 30px;
}
#signUpForm .form-footer button{
  background-color: #1B9F56;
  border: 1px solid #1B9F56 !important;
  color: #ffffff;
  border: none;
  padding: 13px 30px;
  font-size: 1.5em;
  cursor: pointer;
  border-radius: 5px;
  flex: 1;
  margin-top: 5px;
}
#signUpForm .form-footer button:hover {
opacity: 0.8;
}

#signUpForm .form-footer #prevBtn {
  background-color: #fff;
  color: #1B9F56;
}

.fun-history {
  width: 100%;
  padding: 0 300px;
  margin-bottom: 80px;
}

.fun-history .title {
  font-size: 28px;
  font-weight: bold;
  color: #FFF;
  margin-bottom: 15px;
}


.fun-history .table-container {
  max-height: 600px; 
  overflow-y: auto; 
  overflow-x: hidden;
  border-radius: 12px;
  overflow: scroll;
  width: 100%;
  scrollbar-width: thin; 
  scrollbar-color: rgb(27, 101, 43) transparent;
}


.table-fun-history {
  font-size: 20px;
  font-family: 'montserrat';
  font-weight: 600;
  border-radius: 12px;
  overflow: hidden;
}

.table-fun-history tr td{
  padding: 15px;
}

.table-fun-history th{
  padding: 15px;
}

.table-fun-history .time {
  text-align: right;
  max-width: 150px;
  padding-right: 50px;
  color: #333;
}

.table-fun-history .money {
  font-weight: bold;
  color: #35973F;
}



@media screen and (max-width: 1600px) {
  div.main {
    padding-top: 50px;
  }
  
  div.main div.top {
    width: 80%;
  }
  
  div.main div.top h2 {
    font-size: 40px;
    line-height: 130%;
  }
  
  div.main div.top p {
    font-size: 22px;
    line-height: 130%;
  }
  
  .donate {
    width: 100%;
    padding: 0 200px;
  }
  
  .quy {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 20px;
  }
  
  .quy {
    label {
      font-size: 18px;
      margin-bottom: 0px;
      margin-top: 15px;
    }
  
    form {
      padding: 50px 50px;
      border-radius: 15px;
    }
  
    .btn-donate {
      padding: 10px 20px;
      border: 2px solid #1B9F56;
      color: #1B9F56;
      border-radius: 5px;
      font-size: 24px;
      margin-right: 0;
      margin-bottom: 20px;
      width: 100%;
    }
  
    .input-donate {
      padding: 10px 20px !important;
      border-radius: 5px !important;
      font-size: 24px !important;
      margin-right: 0 !important;
      margin-bottom: 20px !important;
      width: 100% !important;
    }
  
    .money-donate {
      padding: 10px 20px !important;
      border-radius: 5px !important;
      font-size: 36px !important;
      margin-right: 0 !important;
      margin-bottom: 20px !important;
      width: 100% !important;
    }
  }
  
  .button {
    text-align: center;
  }
  
  .quy .qr-code {
    width: 50%;
    height: 600px;
    border-radius: 12px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .quy .img-qr {
    height: 80%;
    width: auto;
    text-align: center;
  
    img {
      height: 100%;
      object-fit: contain;
      border-radius: 15px;
    }
  }
  
  .quy .transfer-form {
    width: 40%;
  }
  
  #signUpForm {
    padding: 40px;
    border-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 50%;
  }
  #signUpForm .form-header {
    gap: 3px;
    text-align: center;
    font-size: 1em;
  }
  #signUpForm .form-header .stepIndicator {
    position: relative;
    flex: 1;
    padding-bottom: 30px;
  }
  #signUpForm .form-header .stepIndicator.active {
    font-weight: 600;
  }
  #signUpForm .form-header .stepIndicator.finish {
    font-weight: 600;
    color: #1B9F56;
  }
  #signUpForm .form-header .stepIndicator::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 9;
    width: 20px;
    height: 20px;
    background-color: #d5efed;
    border-radius: 50%;
    border: 3px solid #ecf5f4;
  }
  #signUpForm .form-header .stepIndicator.active::before {
    background-color: #1B9F56;
    border: 3px solid #d5f9f6;
  }
  #signUpForm .form-header .stepIndicator.finish::before {
    background-color: #1B9F56;
    border: 3px solid #e3e3e3;
  }
  #signUpForm .form-header .stepIndicator::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 8px;
    width: 100%;
    height: 3px;
    background-color: #f3f3f3;
  }
  #signUpForm .form-header .stepIndicator.active::after {
    background-color: #e3e3e3;
  }
  #signUpForm .form-header .stepIndicator.finish::after {
    background-color: #1B9F56;
  }
  #signUpForm .form-header .stepIndicator:last-child:after {
    display: none;
  }
  #signUpForm input {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.4em;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
  }
  #signUpForm input, #signUpForm textarea:focus {
    border: 1px solid #1B9F56;
    outline: 0;
  }
  #signUpForm input.invalid, #signUpForm textarea.invalid {
    border: 1px solid #ffaba5;
  }
  #signUpForm .step {
  display: none;
  }
  
  #signUpForm .title {
    font-size: 24px;
  }
  
  #signUpForm .content {
    font-size: 20px;
  }
  
  #signUpForm .form-footer{
    width: calc(100% - 80px);
    gap: 20px;
    bottom: 30px;
  }
  #signUpForm .form-footer button{
    padding: 13px 30px;
    font-size: 1.5em;
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .fun-history {
    width: 100%;
    padding: 0 200px;
    margin-bottom: 80px;
  }
  
  .fun-history .title {
    font-size: 28px;
    font-weight: bold;
    color: #FFF;
    margin-bottom: 15px;
  }
  
  
  .fun-history .table-container {
    max-height: 600px; 
    overflow-y: auto; 
    overflow-x: hidden;
    border-radius: 12px;
    overflow: scroll;
    width: 100%;
    scrollbar-width: thin; 
    scrollbar-color: rgb(27, 101, 43) transparent;
  }
  
  
  .table-fun-history {
    font-size: 20px;
    font-family: 'montserrat';
    font-weight: 600;
    border-radius: 12px;
    overflow: hidden;
    overflow-x: auto;
  }
  
  .table-fun-history tr td{
    padding: 15px;
    white-space: nowrap;
  }
  
  .table-fun-history th{
    padding: 15px;
    white-space: nowrap;
  }
  
  .table-fun-history .time {
    text-align: right;
    max-width: 150px;
    padding-right: 50px;
    color: #333;
    min-width: 250px;
  }
  
  .table-fun-history .money {
    font-weight: bold;
    color: #35973F;
  }
  
}

@media screen and (max-width: 1199.8px) {
  div.main {
    padding-top: 50px;
  }
  
  div.main div.top {
    width: 80%;
  }
  
  div.main div.top h2 {
    font-size: 34px;
    line-height: 130%;
  }
  
  div.main div.top p {
    font-size: 22px;
    line-height: 130%;
  }
  
  .donate {
    width: 100%;
    padding: 0 100px;
  }
  
  .quy {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 20px;
  }
  
  .quy {
    label {
      font-size: 18px;
      margin-bottom: 0px;
      margin-top: 15px;
    }
  
    form {
      padding: 50px 50px;
      border-radius: 15px;
    }
  
    .btn-donate {
      padding: 10px 20px;
      border: 2px solid #1B9F56;
      color: #1B9F56;
      border-radius: 5px;
      font-size: 24px;
      margin-right: 0;
      margin-bottom: 20px;
      width: 100%;
    }
  
    .input-donate {
      padding: 10px 20px !important;
      border-radius: 5px !important;
      font-size: 20px !important;
      margin-right: 0 !important;
      margin-bottom: 20px !important;
      width: 100% !important;
    }
  
    .money-donate {
      padding: 10px 20px !important;
      border-radius: 5px !important;
      font-size: 30px !important;
      margin-right: 0 !important;
      margin-bottom: 20px !important;
      width: 100% !important;
    }
  }
  
  .button {
    text-align: center;
  }
  
  .quy .qr-code {
    width: 50%;
    height: 600px;
    border-radius: 12px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .quy .img-qr {
    height: 80%;
    width: auto;
    text-align: center;
  
    img {
      height: 100%;
      object-fit: contain;
      border-radius: 15px;
    }
  }
  
  .quy .transfer-form {
    width: 40%;
  }
  
  #signUpForm {
    padding: 40px;
    border-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 50%;
  }
  #signUpForm .form-header {
    gap: 3px;
    text-align: center;
    font-size: 1em;
  }
  #signUpForm .form-header .stepIndicator {
    position: relative;
    flex: 1;
    padding-bottom: 30px;
  }
  #signUpForm .form-header .stepIndicator.active {
    font-weight: 600;
  }
  #signUpForm .form-header .stepIndicator.finish {
    font-weight: 600;
    color: #1B9F56;
  }
  #signUpForm .form-header .stepIndicator::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 9;
    width: 20px;
    height: 20px;
    background-color: #d5efed;
    border-radius: 50%;
    border: 3px solid #ecf5f4;
  }
  #signUpForm .form-header .stepIndicator.active::before {
    background-color: #1B9F56;
    border: 3px solid #d5f9f6;
  }
  #signUpForm .form-header .stepIndicator.finish::before {
    background-color: #1B9F56;
    border: 3px solid #e3e3e3;
  }
  #signUpForm .form-header .stepIndicator::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 8px;
    width: 100%;
    height: 3px;
    background-color: #f3f3f3;
  }
  #signUpForm .form-header .stepIndicator.active::after {
    background-color: #e3e3e3;
  }
  #signUpForm .form-header .stepIndicator.finish::after {
    background-color: #1B9F56;
  }
  #signUpForm .form-header .stepIndicator:last-child:after {
    display: none;
  }
  #signUpForm input {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.4em;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
  }
  #signUpForm input, #signUpForm textarea:focus {
    border: 1px solid #1B9F56;
    outline: 0;
  }
  #signUpForm input.invalid, #signUpForm textarea.invalid {
    border: 1px solid #ffaba5;
  }
  #signUpForm .step {
  display: none;
  }
  
  #signUpForm .title {
    font-size: 20px;
  }
  
  #signUpForm .content {
    font-size: 16px;
  }
  
  #signUpForm .form-footer{
    width: calc(100% - 80px);
    gap: 20px;
    bottom: 30px;
  }
  #signUpForm .form-footer button{
    padding: 13px 30px;
    font-size: 1.2em;
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .fun-history {
    width: 100%;
    padding: 0 100px;
    margin-bottom: 80px;
  }
  
  .fun-history .title {
    font-size: 24px;
    font-weight: bold;
    color: #FFF;
    margin-bottom: 15px;
  }
  
  
  .fun-history .table-container {
    max-height: 600px; 
    overflow-y: auto; 
    overflow-x: hidden;
    border-radius: 12px;
    overflow: scroll;
    width: 100%;
    scrollbar-width: thin; 
    scrollbar-color: rgb(27, 101, 43) transparent;
  }
  
  
  .table-fun-history {
    font-size: 20px;
    font-family: 'montserrat';
    font-weight: 600;
    border-radius: 12px;
    overflow: hidden;
    overflow-x: auto;
  }
  
  .table-fun-history tr td{
    padding: 15px;
    white-space: nowrap;
  }
  
  .table-fun-history th{
    padding: 15px;
    white-space: nowrap;
  }
  
  .table-fun-history .time {
    text-align: right;
    max-width: 150px;
    padding-right: 50px;
    color: #333;
    min-width: 250px;
  }
  
  .table-fun-history .money {
    font-weight: bold;
    color: #35973F;
  }
  
}

@media screen and (max-width: 991.8px) {
  div.main {
    padding-top: 50px;
  }
  
  div.main div.top {
    width: 80%;
  }
  
  div.main div.top h2 {
    font-size: 34px;
    line-height: 130%;
  }
  
  div.main div.top p {
    font-size: 22px;
    line-height: 130%;
  }
  
  .donate {
    width: 100%;
    padding: 0 100px;
  }
  
  .quy {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 20px;
  }
  
  .quy {
    label {
      font-size: 18px;
      margin-bottom: 0px;
      margin-top: 15px;
    }
  
    form {
      padding: 50px 50px;
      border-radius: 15px;
    }
  
    .btn-donate {
      padding: 10px 20px;
      border: 2px solid #1B9F56;
      color: #1B9F56;
      border-radius: 5px;
      font-size: 24px;
      margin-right: 0;
      margin-bottom: 20px;
      width: 100%;
    }
  
    .input-donate {
      padding: 10px 20px !important;
      border-radius: 5px !important;
      font-size: 20px !important;
      margin-right: 0 !important;
      margin-bottom: 20px !important;
      width: 100% !important;
    }
  
    .money-donate {
      padding: 10px 20px !important;
      border-radius: 5px !important;
      font-size: 30px !important;
      margin-right: 0 !important;
      margin-bottom: 20px !important;
      width: 100% !important;
    }
  }
  
  .button {
    text-align: center;
  }
  
  .quy .qr-code {
    width: 100%;
    height: 600px;
    border-radius: 12px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  
  .quy .img-qr {
    height: 80%;
    width: auto;
    text-align: center;
  
    img {
      height: 100%;
      object-fit: contain;
      border-radius: 15px;
    }
  }

  
  #signUpForm {
    padding: 40px;
    border-radius: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    height: 550px;
  }
  #signUpForm .form-header {
    gap: 3px;
    text-align: center;
    font-size: 1em;
  }
  #signUpForm .form-header .stepIndicator {
    position: relative;
    flex: 1;
    padding-bottom: 30px;
  }
  #signUpForm .form-header .stepIndicator.active {
    font-weight: 600;
  }
  #signUpForm .form-header .stepIndicator.finish {
    font-weight: 600;
    color: #1B9F56;
  }
  #signUpForm .form-header .stepIndicator::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 9;
    width: 20px;
    height: 20px;
    background-color: #d5efed;
    border-radius: 50%;
    border: 3px solid #ecf5f4;
  }
  #signUpForm .form-header .stepIndicator.active::before {
    background-color: #1B9F56;
    border: 3px solid #d5f9f6;
  }
  #signUpForm .form-header .stepIndicator.finish::before {
    background-color: #1B9F56;
    border: 3px solid #e3e3e3;
  }
  #signUpForm .form-header .stepIndicator::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 8px;
    width: 100%;
    height: 3px;
    background-color: #f3f3f3;
  }
  #signUpForm .form-header .stepIndicator.active::after {
    background-color: #e3e3e3;
  }
  #signUpForm .form-header .stepIndicator.finish::after {
    background-color: #1B9F56;
  }
  #signUpForm .form-header .stepIndicator:last-child:after {
    display: none;
  }
  #signUpForm input {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.4em;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
  }
  #signUpForm input, #signUpForm textarea:focus {
    border: 1px solid #1B9F56;
    outline: 0;
  }
  #signUpForm input.invalid, #signUpForm textarea.invalid {
    border: 1px solid #ffaba5;
  }
  #signUpForm .step {
  display: none;
  }
  
  #signUpForm .title {
    font-size: 24px;
  }
  
  #signUpForm .content {
    font-size: 18px;
  }
  
  #signUpForm .form-footer{
    width: calc(100% - 80px);
    gap: 20px;
    bottom: 30px;
  }
  #signUpForm .form-footer button{
    padding: 13px 30px;
    font-size: 1.2em;
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .fun-history {
    width: 100%;
    padding: 0 100px;
    margin-bottom: 80px;
  }
  
  .fun-history .title {
    font-size: 24px;
    font-weight: bold;
    color: #FFF;
    margin-bottom: 15px;
  }
  
  
  .fun-history .table-container {
    max-height: 600px; 
    overflow-y: auto; 
    overflow-x: hidden;
    border-radius: 12px;
    overflow: scroll;
    width: 100%;
    scrollbar-width: thin; 
    scrollbar-color: rgb(27, 101, 43) transparent;
  }
  
  
  .table-fun-history {
    font-size: 20px;
    font-family: 'montserrat';
    font-weight: 600;
    border-radius: 12px;
    overflow: hidden;
    overflow-x: auto;
  }
  
  .table-fun-history tr td{
    padding: 15px;
    white-space: nowrap;
  }
  
  .table-fun-history th{
    padding: 15px;
    white-space: nowrap;
  }
  
  .table-fun-history .time {
    text-align: right;
    max-width: 150px;
    padding-right: 50px;
    color: #333;
    min-width: 250px;
  }
  
  .table-fun-history .money {
    font-weight: bold;
    color: #35973F;
  }
}

@media screen and (max-width: 767.8px){
  div.main {
    padding-top: 50px;
  }
  
  div.main div.top {
    width: 80%;
  }
  
  div.main div.top h2 {
    font-size: 34px;
    line-height: 130%;
  }
  
  div.main div.top p {
    font-size: 22px;
    line-height: 130%;
  }
  
  .donate {
    width: 100%;
    padding: 0 100px;
  }
  
  .quy {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 20px;
  }
  
  .quy {
    label {
      font-size: 18px;
      margin-bottom: 0px;
      margin-top: 15px;
    }
  
    form {
      padding: 50px 50px;
      border-radius: 15px;
    }
  
    .btn-donate {
      padding: 10px 20px;
      border: 2px solid #1B9F56;
      color: #1B9F56;
      border-radius: 5px;
      font-size: 24px;
      margin-right: 0;
      margin-bottom: 20px;
      width: 100%;
    }
  
    .input-donate {
      padding: 10px 20px !important;
      border-radius: 5px !important;
      font-size: 20px !important;
      margin-right: 0 !important;
      margin-bottom: 20px !important;
      width: 100% !important;
    }
  
    .money-donate {
      padding: 10px 20px !important;
      border-radius: 5px !important;
      font-size: 30px !important;
      margin-right: 0 !important;
      margin-bottom: 20px !important;
      width: 100% !important;
    }
  }
  
  .button {
    text-align: center;
  }
  
  .quy .qr-code {
    width: 100%;
    height: 600px;
    border-radius: 12px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  
  .quy .img-qr {
    height: 80%;
    width: auto;
    text-align: center;
  
    img {
      height: 100%;
      object-fit: contain;
      border-radius: 15px;
    }
  }

  
  #signUpForm {
    padding: 40px;
    border-radius: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    height: 550px;
  }
  #signUpForm .form-header {
    gap: 3px;
    text-align: center;
    font-size: 0.9em;
  }
  #signUpForm .form-header .stepIndicator {
    position: relative;
    flex: 1;
    padding-bottom: 30px;
  }
  #signUpForm .form-header .stepIndicator.active {
    font-weight: 600;
  }
  #signUpForm .form-header .stepIndicator.finish {
    font-weight: 600;
    color: #1B9F56;
  }
  #signUpForm .form-header .stepIndicator::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 9;
    width: 20px;
    height: 20px;
    background-color: #d5efed;
    border-radius: 50%;
    border: 3px solid #ecf5f4;
  }
  #signUpForm .form-header .stepIndicator.active::before {
    background-color: #1B9F56;
    border: 3px solid #d5f9f6;
  }
  #signUpForm .form-header .stepIndicator.finish::before {
    background-color: #1B9F56;
    border: 3px solid #e3e3e3;
  }
  #signUpForm .form-header .stepIndicator::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 8px;
    width: 100%;
    height: 3px;
    background-color: #f3f3f3;
  }
  #signUpForm .form-header .stepIndicator.active::after {
    background-color: #e3e3e3;
  }
  #signUpForm .form-header .stepIndicator.finish::after {
    background-color: #1B9F56;
  }
  #signUpForm .form-header .stepIndicator:last-child:after {
    display: none;
  }
  #signUpForm input {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.2em;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
  }
  #signUpForm input, #signUpForm textarea:focus {
    border: 1px solid #1B9F56;
    outline: 0;
  }
  #signUpForm input.invalid, #signUpForm textarea.invalid {
    border: 1px solid #ffaba5;
  }
  #signUpForm .step {
  display: none;
  }
  
  #signUpForm .title {
    font-size: 20px;
  }
  
  #signUpForm .content {
    font-size: 16px;
  }
  
  #signUpForm .form-footer{
    width: calc(100% - 80px);
    gap: 20px;
    bottom: 30px;
  }
  #signUpForm .form-footer button{
    padding: 13px 30px;
    font-size: 1.2em;
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .fun-history {
    width: 100%;
    padding: 0 100px;
    margin-bottom: 80px;
  }
  
  .fun-history .title {
    font-size: 24px;
    font-weight: bold;
    color: #FFF;
    margin-bottom: 15px;
  }
  
  
  .fun-history .table-container {
    max-height: 600px; 
    overflow-y: auto; 
    overflow-x: hidden;
    border-radius: 12px;
    overflow: scroll;
    width: 100%;
    scrollbar-width: thin; 
    scrollbar-color: rgb(27, 101, 43) transparent;
  }
  
  
  .table-fun-history {
    font-size: 20px;
    font-family: 'montserrat';
    font-weight: 600;
    border-radius: 12px;
    overflow: hidden;
    overflow-x: auto;
  }
  
  .table-fun-history tr td{
    padding: 15px;
    white-space: nowrap;
  }
  
  .table-fun-history th{
    padding: 15px;
    white-space: nowrap;
  }
  
  .table-fun-history .time {
    text-align: right;
    max-width: 150px;
    padding-right: 50px;
    color: #333;
    min-width: 250px;
  }
  
  .table-fun-history .money {
    font-weight: bold;
    color: #35973F;
  }
}

@media screen and (max-width: 575.8px){
  div.main {
    padding-top: 50px;
  }
  
  div.main div.top {
    width: 90%;
  }
  
  div.main div.top h2 {
    font-size: 22px;
    line-height: 130%;
  }
  
  div.main div.top p {
    font-size: 18px;
    line-height: 130%;
  }
  
  .donate {
    width: 100%;
    padding: 0 30px;
  }
  
  .quy {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 20px;
  }
  
  .quy {
    label {
      font-size: 18px;
      margin-bottom: 0px;
      margin-top: 15px;
    }
  
    form {
      padding: 30px 20px;
      border-radius: 15px;
    }
  
    .btn-donate {
      padding: 5px 10px;
      border: 2px solid #1B9F56;
      color: #1B9F56;
      border-radius: 5px;
      font-size: 18px;
      margin-right: 0;
      margin-bottom: 10px;
      width: 100%;
    }
  
    .input-donate {
      padding: 5px 10px !important;
      border-radius: 5px !important;
      font-size: 18px !important;
      margin-right: 0 !important;
      margin-bottom: 10px !important;
      width: 100% !important;
    }
  
    .money-donate {
      padding: 5px 10px !important;
      border-radius: 5px !important;
      font-size: 24px !important;
      margin-right: 0 !important;
      margin-bottom: 20px !important;
      width: 100% !important;
    }
  }
  
  .button {
    text-align: center;
  }
  
  .quy .qr-code {
    width: 100%;
    height: 400px;
    border-radius: 12px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  
  .quy .img-qr {
    height: 80%;
    width: auto;
    text-align: center;
  
    img {
      height: 100%;
      object-fit: contain;
      border-radius: 15px;
    }
  }

  
  #signUpForm {
    padding: 20px;
    border-radius: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    height: 450px;
  }
  #signUpForm .form-header {
    gap: 3px;
    text-align: center;
    font-size: 0.7em;
  }
  #signUpForm .form-header .stepIndicator {
    position: relative;
    flex: 1;
    padding-bottom: 30px;
  }
  #signUpForm .form-header .stepIndicator.active {
    font-weight: 600;
  }
  #signUpForm .form-header .stepIndicator.finish {
    font-weight: 600;
    color: #1B9F56;
  }
  #signUpForm .form-header .stepIndicator::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 9;
    width: 20px;
    height: 20px;
    background-color: #d5efed;
    border-radius: 50%;
    border: 3px solid #ecf5f4;
  }
  #signUpForm .form-header .stepIndicator.active::before {
    background-color: #1B9F56;
    border: 3px solid #d5f9f6;
  }
  #signUpForm .form-header .stepIndicator.finish::before {
    background-color: #1B9F56;
    border: 3px solid #e3e3e3;
  }
  #signUpForm .form-header .stepIndicator::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 8px;
    width: 100%;
    height: 3px;
    background-color: #f3f3f3;
  }
  #signUpForm .form-header .stepIndicator.active::after {
    background-color: #e3e3e3;
  }
  #signUpForm .form-header .stepIndicator.finish::after {
    background-color: #1B9F56;
  }
  #signUpForm .form-header .stepIndicator:last-child:after {
    display: none;
  }
  #signUpForm input {
    padding: 8px 10px;
    width: 100%;
    font-size: 1em;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
  }
  #signUpForm input, #signUpForm textarea:focus {
    border: 1px solid #1B9F56;
    outline: 0;
  }
  #signUpForm input.invalid, #signUpForm textarea.invalid {
    border: 1px solid #ffaba5;
  }
  #signUpForm .step {
  display: none;
  }
  
  #signUpForm .title {
    font-size: 18px;
    margin-bottom: 10px !important;
  }
  
  #signUpForm .content {
    font-size: 16px;
  }
  
  #signUpForm .form-footer{
    width: calc(100% - 80px);
    gap: 20px;
    bottom: 30px;
  }
  #signUpForm .form-footer button{
    padding: 10px 20px;
    font-size: 1em;
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .fun-history {
    width: 100%;
    padding: 0 30px;
    margin-bottom: 80px;
  }
  
  .fun-history .title {
    font-size: 20px;
    font-weight: bold;
    color: #FFF;
    margin-bottom: 15px;
  }
  
  
  .fun-history .table-container {
    max-height: 400px; 
    overflow-y: auto; 
    overflow-x: hidden;
    border-radius: 12px;
    overflow: scroll;
    width: 100%;
    scrollbar-width: thin; 
    scrollbar-color: rgb(27, 101, 43) transparent;
  }
  
  
  .table-fun-history {
    font-size: 16px;
    font-family: 'montserrat';
    font-weight: 600;
    border-radius: 12px;
    overflow: hidden;
    overflow-x: auto;
  }
  
  .table-fun-history tr td{
    padding: 15px;
    white-space: nowrap;
  }
  
  .table-fun-history th{
    padding: 15px;
    white-space: nowrap;
  }
  
  .table-fun-history .time {
    text-align: right;
    max-width: 150px;
    padding-right: 50px;
    color: #333;
    min-width: 250px;
  }
  
  .table-fun-history .money {
    font-weight: bold;
    color: #35973F;
  }
}



























/* @media screen and (max-width: 1600px) {
  div.main {
    padding-top: 50px;
  }
  
  div.main div.top {
    width: 80%;
  }
  
  div.main div.top h2 {
    font-size: 40px;
  }
  
  div.main div.top p {
    font-size: 22px;
  }
  
  
  div.main div.bot {
    width: 100%;
  }

  .donate {
    width: 100%;
    padding: 0 100px;
    margin-bottom: 50px;
  }
  
  .button-history {
    padding: 10px 30px;
    font-size: 18px;
  }
  
  .quy {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .quy .title {
    font-size: 20px;
  }
  
  .quy {
    label {
      font-size: 18px;
      margin-bottom: 0px;
      margin-top: 15px;
    }
  
    form {
      padding: 50px 50px;
    }
  
    input {
      padding-left: 10px;
      font-size: 18px;
    }
  
    button {
      padding: 5px 20px;
      font-size: 18px;
      margin-top: 30px;
    }
  }
  
  .quy-row {
    padding: 0 50px;
  }
  
  .quy .img-qr {
    width: 40%;
    height: 400px;
    margin-right: 50px;

    img {
      height: 100%;
    }
  }
  
  .quy .transfer-form {
    width: 40%;
  }


  #signUpForm {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 50%;
    position: relative;
  }
  #signUpForm .form-header {
    gap: 5px;
    text-align: center;
    font-size: 1.2em;
  }
  #signUpForm .form-header .stepIndicator {
    position: relative;
    flex: 1;
    padding-bottom: 30px;
  }
  #signUpForm .form-header .stepIndicator.active {
    font-weight: 600;
  }
  #signUpForm .form-header .stepIndicator.finish {
    font-weight: 600;
    color: #1B9F56;
  }
  #signUpForm .form-header .stepIndicator::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 9;
    width: 20px;
    height: 20px;
    background-color: #d5efed;
    border-radius: 50%;
    border: 3px solid #ecf5f4;
  }
  #signUpForm .form-header .stepIndicator.active::before {
    background-color: #1B9F56;
    border: 3px solid #d5f9f6;
  }
  #signUpForm .form-header .stepIndicator.finish::before {
    background-color: #1B9F56;
    border: 3px solid #e3e3e3;
  }
  #signUpForm .form-header .stepIndicator::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 8px;
    width: 100%;
    height: 3px;
    background-color: #f3f3f3;
  }
  #signUpForm .form-header .stepIndicator.active::after {
    background-color: #e3e3e3;
  }
  #signUpForm .form-header .stepIndicator.finish::after {
    background-color: #1B9F56;
  }
  #signUpForm .form-header .stepIndicator:last-child:after {
    display: none;
  }
  #signUpForm input {
    padding: 15px 20px;
    width: 100%;
    font-size: 1.4em;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
  }
  #signUpForm input, #signUpForm textarea:focus {
    border: 1px solid #1B9F56;
    outline: 0;
  }
  #signUpForm input.invalid, #signUpForm textarea.invalid {
    border: 1px solid #ffaba5;
  }
  #signUpForm .step {
  display: none;
  }
  
  #signUpForm .title {
    font-size: 24px;
  }
  
  #signUpForm .content {
    font-size: 22px;
  }
  
  #signUpForm .form-footer{
    width: calc(100% - 80px);
    overflow:auto;
    gap: 20px;
    position: absolute;
    bottom: 30px;
  }
  #signUpForm .form-footer button{
    background-color: #1B9F56;
    border: 1px solid #1B9F56 !important;
    color: #ffffff;
    border: none;
    padding: 13px 30px;
    font-size: 1.5em;
    cursor: pointer;
    border-radius: 5px;
    flex: 1;
    margin-top: 5px;
  }
  #signUpForm .form-footer button:hover {
  opacity: 0.8;
  }
  
  #signUpForm .form-footer #prevBtn {
    background-color: #fff;
    color: #1B9F56;
  }
  
}

@media screen and (max-width: 1199.8px) {
  div.main {
    padding-top: 50px;
  }
  
  div.main div.top {
    width: 90%;
  }
  
  div.main div.top h2 {
    font-size: 30px;
  }
  
  div.main div.top p {
    font-size: 18px;
  }
  
  
  div.main div.bot {
    width: 100%;
  }

  .donate {
    width: 100%;
    padding: 0 100px;
    margin-bottom: 50px;
  }
  
  .button-history {
    padding: 10px 30px;
    font-size: 18px;
  }
  
  .quy {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .quy .title {
    font-size: 20px;
  }
  
  .quy {
    label {
      font-size: 18px;
      margin-bottom: 0px;
      margin-top: 15px;
    }
  
    form {
      padding: 50px 50px;
    }
  
    input {
      padding-left: 10px;
      font-size: 18px;
    }
  
    button {
      padding: 5px 20px;
      font-size: 18px;
      margin-top: 30px;
    }
  }
  
  .quy-row {
    padding: 0 50px;
  }
  
  .quy .img-qr {
    width: 40%;
    height: 400px;
    margin-right: 50px;

    img {
      height: 100%;
    }
  }
  
  .quy .transfer-form {
    width: 40%;
  }
}

@media screen and (max-width: 991.8px) {
  div.main {
    padding-top: 50px;
  }
  
  div.main div.top {
    width: 90%;
  }
  
  div.main div.top h2 {
    font-size: 30px;
  }
  
  div.main div.top p {
    font-size: 18px;
  }
  
  
  div.main div.bot {
    width: 100%;
  }

  .donate {
    width: 100%;
    padding: 0 100px;
    margin-bottom: 50px;
    flex-wrap: wrap;
  }
  
  .button-history {
    padding: 10px 30px;
    font-size: 18px;
  }
  
  .quy {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .quy .title {
    font-size: 16px;
  }
  
  .quy {
    label {
      font-size: 18px;
      margin-bottom: 0px;
      margin-top: 15px;
    }
  
    form {
      padding: 50px 50px;
    }
  
    input {
      padding-left: 10px;
      font-size: 18px;
    }
  
    button {
      padding: 5px 20px;
      font-size: 18px;
      margin-top: 30px;
    }
  }
  
  .quy-row {
    padding: 0 50px;
  }
  
  .quy .img-qr {
    width: 70%;
    height: 400px;

    img {
      height: 100%;
    }
  }
  
  .quy .transfer-form {
    width: 100%;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767.8px){
  
}

@media screen and (max-width: 575.8px){
  div.main {
    padding-top: 50px;
  }
  
  div.main div.top {
    width: 90%;
  }
  
  div.main div.top h2 {
    font-size: 18px;
  }
  
  div.main div.top p {
    font-size: 14px;
  }
  
  
  div.main div.bot {
    width: 100%;
  }

  .donate {
    width: 100%;
    padding: 0 20px;
    margin-bottom: 50px;
    flex-wrap: wrap;
  }
  
  .button-history {
    padding: 10px 30px;
    font-size: 18px;
  }
  
  .quy {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .quy .title {
    font-size: 16px;
  }
  
  .quy {
    label {
      font-size: 18px;
      margin-bottom: 0px;
      margin-top: 15px;
    }
  
    form {
      padding: 50px 50px;
    }
  
    input {
      padding-left: 10px;
      font-size: 18px;
    }
  
    button {
      padding: 5px 20px;
      font-size: 18px;
      margin-top: 30px;
    }
  }
  
  .quy-row {
    padding: 0 50px;
  }
  
  .quy .img-qr {
    width: 70%;
    height: 350px;

    img {
      height: 100%;
    }
  }
  
  .quy .transfer-form {
    width: 100%;
    margin-bottom: 30px;
  }

  .modal-history {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 10px;
    z-index: 1000;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 80%;
  }

  .transaction-history {
    max-height: 600px;
    overflow-y: auto;
    padding-right: 10px;
    margin-bottom: 20px;
  }
  
  .transaction-history ul {
    list-style-type: none;
    padding: 0;
  }
  
  .transaction-history li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
  }
  
  .transaction-history li .name {
    font-weight: 600;
  }
  
  .transaction-history li span {
    font-weight: 600;
    color: #35973F;
  }
  
  .transaction-history h3 {
    margin-bottom: 10px;
  }

  .button-history {
    padding: 10px 30px;
    border: none;
    background-color: #ffffff;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 800;
    color: black;
    border: 2px solid rgb(144, 144, 144);
  }

} */