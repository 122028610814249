.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0.5rem;
}

.userInfo {
    display: flex;
    margin-bottom: 0.5rem;
}

.avatar {
    width: 2.7rem;
    height: 2.7rem;
    border-radius: 50%;
    object-fit: cover;
}

.details {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
    line-height: 0.6rem;
    margin-top: 0.2rem;
}

.iconContainer {
    margin-left: auto;
    font-size: 1.4rem;
    margin-top: auto;
}

.icon {
    margin-left: 1rem;
    margin-right: 2rem;
}@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(100%);
        opacity: 0;
    }
}

.userInfo {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.5s ease-out;
    transition: all 0.5s ease-out;
    width: 95%;
}

.userInfo.exit {
    animation: slideOut 0.5s ease-out forwards;
}
