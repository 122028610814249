.container {
    width: 100%;
    max-height: 50rem; /* Adjust this as needed, can also use a percentage value */
    background-color: rgb(255, 255, 255);
    display: flex;
    padding: 0;
    flex-direction: column;
    overflow-y: auto;
    height: 100rem;  

    input:focus {
        outline: none;
    }
}.containeruser {
    width: 100%;
    height: 4rem;
    background-color: rgb(255, 255, 255);
    display: flex;
    padding-left: 0.3rem;
    padding-top: 0.2rem;
    cursor: pointer; /* Add cursor pointer */
}

.containeruser:hover {
    background-color: rgb(212, 248, 213);
}

.selected {
    background-color: rgb(212, 248, 213); /* Background color when selected */
}

.useravatar {
    position: relative;
}

.useravatar img {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    margin: 3px;
    object-fit: cover;
}

.useravatar .activeDot {
    position: absolute;
    bottom: 3px;
    right: 3px;
    width: 0.8rem;
    height: 0.8rem;
    background-color: green;
    border-radius: 50%;
    border: 1px solid white;
}

.userinfo {
    display: flex;
    flex-direction: column;
    margin-left: 0.3rem;
    margin-top: 0.2rem;
    width: 100%;
    height: 100%;
}

.userinfo h6 {
    font-weight: 600;
    max-width: 80%;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 5px;
    font-size: 20px;
}

.userinfo p {
    margin: 0;
    font-weight: revert;
    max-width: 11rem; 
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap;
}@media (max-width: 450px) {
}
