.container {
    display: flex;
    flex-direction: column;
    height: 100%;

    textArea:focus {
        outline: none;
    }
    overflow: hidden;
}

.containerHeader {
    display: flex;
    align-items: center;
    padding: 0.2rem 0.7rem;
    border-bottom: 2px solid #dfdfdf;
    background-color: #ffffff;
}

.containerHeader img {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 0.3rem;
}

.containerHeaderInfo {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    font-weight: revert;
    max-width: 60%; 
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap;
}

.containerHeaderInfo h6 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: bold;
}

.containerHeaderInfo p {
    margin: 0;
    margin-bottom: 0;
    font-size: 1rem;
    color: #888;
}

.containerHeaderSettings {
    margin-left: auto;
    margin-right: 1rem;
    display:  flex;
}

.containerHeaderSettings img {
    width: 1.7rem;
    height: 1.7rem;
    cursor: pointer;
}.containerHeaderSettings i {
    font-size : 1.3rem;
    margin-right : 1rem;
    margin-top:  0.5rem;
    display: block;
    cursor: pointer;
}.containerHeaderSettingsvideo {
    display: block;
}

.content {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    background-color: #fff;
}

.content::-webkit-scrollbar {
    display: none;
}

.footer {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    background-color: #ffffff;
    border-top: 1px solid #dfdfdf;
}

.footerinput {
    display: flex;
    align-items: center;
}

.inputWrapper {
    position: relative;
    flex: 1;
}

.inputWrapper textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    resize: none;
    height: 2.5rem;
    font-size: 1rem;
    padding-right: 2.5rem;
    overflow-y: hidden;
    
}

.inputWrapper i{
    /* padding-right: 0.4rem ; */
    position: absolute;
    right: 0.75rem;
    top: calc(50% - 4px);
    transform: translateY(-50%);
    font-size: 1.5rem;
    color: #1f682f;
}

.inputWrapper label {
    position: absolute;
    right: 0.6rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.5rem;
    color: #1f682f;
}

.footerinput button {
    padding: 0.5rem 1rem;
    background-color: #35973F;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-left: 0.5rem;
    font-size: 1rem;
    margin-bottom: 0.4rem;
}

.footerinput button:hover {
    background-color: #57ce51;
}

.selectedImagePreview img {
    width: 8rem;
    height: auto;
    margin-bottom: 1rem;
    margin-left: 1rem;
    border-radius: 10px;
}

.message {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    max-width: 100%;
    width: auto;
}

.message img {
    max-width: 50%;
    height: auto;
    margin-top: 0.3rem;
    border-radius: 10px;
}.avataruser img {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    object-fit: cover;
}


.message.small {
    font-size: 0.8rem;
    color: #888;
    margin-top: 0.3rem;
}

.alignStart {
    align-items: flex-start;
}

.alignEnd {
    align-items: flex-end;
}

.contentRight {
    background-color: #399435;
    margin-left: auto;
    border-radius: 25px 4px 25px 25px;
    padding: 10px 15px;
    padding-bottom: 2px;
    color: #ffffff;
    max-width: 70%;
}.contentRight h5 {
    max-width: 100%;
    word-wrap: break-word; /* Breaks long words */
    overflow-wrap: break-word; /* Ensures long words break appropriately */
}.contentLeft {
    background-color: #d2d2d2;
    border-radius: 4px 25px 25px 25px;
    padding: 10px 15px;
    margin-right: auto;
    padding-top: 3px;
    padding-bottom: 2px;
    max-width: 70%;
}.contentLeft h5 {
    max-width: 100%;
    word-wrap: break-word; /* Breaks long words */
    overflow-wrap: break-word; /* Ensures long words break appropriately */
}

.messageTime {
    font-size: 0.8rem;
    color: #888;
    margin-top: 0.3rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

.imageleft {
    align-items: flex-start;
}

.imageright {
    align-items: flex-end;
}.containerHeader i {
   display: none;
}.meetingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}@media (max-width : 560px) {
    .containerHeader img{
    margin-left: 0.4rem;
    width: 2.7rem;
    height: 2.7rem;
    margin-top: 0.4rem;
}.containerHeaderInfo p {
    font-size: 0.8rem;
    margin-top: 0;
}.containerHeaderInfo h5 {
    font-size: 1rem;
}.containerHeaderSettings img {
    width: 1.5rem;
    height: 1.5rem;
}.containerHeader i {
   display: block;
}.containerHeaderSettings i {
    font-size : 1.3rem;
    margin-right : 0;
    margin-top:  0.5rem;
}
}