@font-face {
    font-family: 'RaleWay';
    src: url('../../../component/Fonts/Raleway/Raleway-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'Radio Canada';
    src: url('../../../component/Fonts/Radio_Canada/RadioCanada-VariableFont_wdth\,wght.ttf');
}




div.main {
    width: 567px;
    /* height: 265px; */
    flex-shrink: 0;

    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.25);

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}


div.main div.block1 {
    width: 100%;
    /* height: 110px; */
    flex-shrink: 0;

    display: flex;
    padding: 20px ;

    /* border: 1px solid red; */
}


div.main div.block1 div.img img {
    width: 110px;
    height: 110px;
    flex-shrink: 0;
    object-fit: cover;

    border-radius: 50%; 
}


div.main div.block1 div.txtBlock {
    margin-left: 1rem;
    padding-top: 20px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    /* border: 1px solid red; */
}


div.main div.block1 div.txtBlock p.txt1 {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%; /* 39px */
}


div.main div.block1 div.txtBlock p.txt2 {
    margin-top: -15px;

    color: #474747;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 31.2px */
}


div.main div.block2 {
    width: 100%; 
    padding: 0 20px 20px 20px;
    flex-shrink: 0; 

    /* border: 1px solid red; */
}


div.main div.block2 p {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 27.3px */
}





/* Scrolling animation */
.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(100%);
    transition: all 1s;
}


.show {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
}




@media screen and (max-width: 1500px) {
    div.main {
        width: 470px;
    }
    div.main div.block1 {

    }
    
    
    div.main div.block1 div.img img {
        width: 80px;
        height: 80px;
    }
    
    
    div.main div.block1 div.txtBlock {
        padding-top: 10px;
    }
    
    
    div.main div.block1 div.txtBlock p.txt1 {
        font-size: 28px;
    }
    
    
    div.main div.block1 div.txtBlock p.txt2 {
        font-size: 24px;
    }
}

@media screen and (max-width: 1199.8px) {
    div.main {
        width: 400px;
    }
    div.main div.block1 {
        
    }
    
    
    div.main div.block1 div.img img {
        width: 80px;
        height: 80px;
    }
    
    
    div.main div.block1 div.txtBlock {
        padding-top: 10px;
    }
    
    
    div.main div.block1 div.txtBlock p.txt1 {
        font-size: 24px;
    }
    
    
    div.main div.block1 div.txtBlock p.txt2 {
        font-size: 20px;
    }
}

@media screen and (max-width: 991.8px) {
    div.main {
        width: 80%;
    }
    div.main div.block1 {
        
    }
    
    
    div.main div.block1 div.img img {
        width: 80px;
        height: 80px;
    }
    
    
    div.main div.block1 div.txtBlock {
        padding-top: 10px;
    }
    
    
    div.main div.block1 div.txtBlock p.txt1 {
        font-size: 26px;
    }
    
    
    div.main div.block1 div.txtBlock p.txt2 {
        font-size: 22px;
    }
}

@media screen and (max-width: 767.8px){
    
}

@media screen and (max-width: 575.8px){
    div.main {
        width: 80%;
    }
    div.main div.block1 {
        padding-bottom: 5px;
    }
    
    div.main div.block1 div.img img {
        width: 50px;
        height: 50px;
    }
    
    div.main div.block1 div.txtBlock {
        padding-top: 5px;
    }
    
    div.main div.block1 div.txtBlock p.txt1 {
        font-size: 20px;
    }
    
    div.main div.block1 div.txtBlock p.txt2 {
        font-size: 16px;
    }

    div.main div.block2 p {
        font-size: 14px;
    }
}