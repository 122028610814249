.CreateCampaign {
  width: 100%;
  padding: 50px 200px;

  h1 {
    font-size: 40px;
    font-weight: 700 ;
    text-align: center;
    color: #35973F;
  }

  hr {
    width: 100px;
    border: 2px solid #35973F;
    opacity: 1;
    margin-bottom: 10px;
    margin-top: 0px;
  }

  label {
    font-size: 22px;
    font-weight: 600;
    color: black;
  }

  input, textArea {
    width: 100%;
    border: 1px solid #c9c9c9;
    border-radius: 5px;
    padding: 10px;
    font-size: 22px;
    font-weight: 500;
    color: black;

    &:focus-visible {
      outline: none;
      border: 1px solid #5fa967;
    }
  }
}

.title {
  font-size: 28px;
  font-weight: 600;
  color: #35973F;
  margin-bottom: 20px;
}

.input-name {
  height: 80px;
  margin-bottom: 15px;
}

.input-desc {
  height: 200px;
  margin-bottom: 20px;
}

.date {
  display: flex;
  justify-content: left;
  margin-bottom: 15px;
}

.date label {
  display: inline-block;
  width: 220px;
}

.date input {
  width: 200px;
  height: 40px;
}

.where {
  display: flex;
  flex-direction: column;
}

.item-where {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.province {
  display: inline-block;
  width: 200px;
}

.district {
  display: inline-block;
  width: 200px;
}


.input-province, .input-district {
  display: inline-block;
  width: 300px;
  height: 40px;
  font-size: 18px;
  color: black;

  &:focus-visible {
    outline: none;
    border: 1px solid #5fa967;
  }
} 

.money-row {
  display: flex;
  margin-bottom: 40px;
  margin-top: 5px;
}

.money {
  display: flex;
  align-items: center;
  justify-content: left;
}

.total-money, .money-by-VNJN {
  display: inline-block;
  width: 220px;
}

.input-total-money, .input-money-by-VNJN {
  height: 40px;
  flex: 1;
  width: 200px;
  font-size: 30px;
  font-weight: bold;
  color: #35973F;
  margin-right: 100px;
}

.timeline-row {
  display: flex;
  justify-content: left;
  margin-bottom: 40px;
}

.timeline-row .timeline {
  display: inline-block;
}

// .time {
//   margin-left: 10px;
//   border-left: 3px solid #001273;
//   padding-left: 40px;
//   width: 300px;
// }

.time .inner-title {
  font-size: 24px;
  font-weight: 700;
  color: #001273;
  margin-bottom: 5px;
  line-height: 100%;
}

.time .desc {
  font-size: 22px;
  font-weight: 600;
  color: #737373;
  margin-bottom: 20px;  
  height: 80px;
}

.location-button {
  padding: 5px 20px;
  border: none;
  background-color: #35973F;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  color: white;
  margin-left: 30px;
}

.row-location {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;

  input{
    width: 100%;
    border: 1px solid #c9c9c9;
    border-radius: 5px;
    padding: 10px;
    font-size: 22px;
    font-weight: 500;
    color: black;
    background-color: white;
  }

  button {
    margin-left: 20px;
    padding: 0px;
    border: none;
    background-color: transparent;
    color: rgb(170, 0, 0);
    font-size: 30px;
  }
}

.input-location {
  height: calc(100% - 100px);
  margin-top: 5px;
}

.contact-row {
  button {
    padding: 10px 30px;
    border: none;
    background-color: #35973F;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    color: white;
  }

  input {
    width: 80%;
    font-size: 22px;
    font-weight: 500;
    padding: 5px 10px;
    margin-top: 10px;

    &:focus-visible {
      outline: none;
      border: 1px solid #5fa967;
      box-shadow: none;
    }
  }
}

.form-contact {
  margin-bottom: 30px;
}

.image-upload-container {
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-top: 60px;

  label {
    padding: 10px 30px;
    border: none;
    background-color: #35973F;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    color: white;
  }
}
.image-upload-button {
  display: inline-block;
}
.image-preview-container {
  width: 60%;
  height: auto;
  aspect-ratio: 2/1;
  margin-top: 20px;
  margin-left: 20%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

.submit-button {
  display: block;
  margin: 0 auto; 
  margin-top: 80px;
  padding: 10px 30px;
  border: none;
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 800;
  color: #35973F;
  border: 3px solid #35973F;
}