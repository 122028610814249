:root {
  --color-text: #35973F;
  --color-desc: #8C8989;
}

.info {
  margin-left: 30px;

  img {
    width: 100%;
    height: 400px;
    border-radius: 20px;
  }

  hr {
    margin: 0;
    width: 60%;
    border: 2px solid var(--color-text);
    opacity: 1;
  }

  p {
    font-size: 22px;
    font-weight: 500;
    color: var(--color-desc);
    margin-bottom: 0;

  }
}

.title {
  font-size: 46px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
  color: var(--color-text);
}

.total {
  margin-top: 16px;
}

.total .number {
  font-size: 75px;
  font-weight: 600;
  color: var(--color-text);
  line-height: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  padding-bottom: 15px;
}

.detail {
  margin-top: 12px;
}

.detail .number {
  font-size: 65px;
  font-weight: 500;
  color: var(--color-text);
  line-height: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-bottom: 15px;
}

.detail {
  
  p {
    padding-top: 10px;
    border-top: 3px solid var(--color-text);
  }
}


@media screen and (max-width: 1600px) {
.info {
  margin-left: 30px;

  img {
    width: 100%;
    height: 380px;
    border-radius: 20px;
  }

  hr {
    margin: 0;
    width: 60%;
    border: 1.9px solid var(--color-text);
    opacity: 1;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    color: var(--color-desc);
    margin-bottom: 0;

  }
}

.title {
  font-size: 38px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
  color: var(--color-text);
}

.total {
  margin-top: 12px;
}

.total .number {
  font-size: 65px;
  font-weight: 600;
  color: var(--color-text);
  line-height: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-bottom: 15px;
}

.detail {
  margin-top: 12px;
}

.detail .number {
  font-size: 55px;
  font-weight: 500;
  color: var(--color-text);
  line-height: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-bottom: 15px;
}

.detail {
  
  p {
    padding-top: 10px;
    border-top: 1px solid var(--color-text);
  }
}

}

// .centeredSpin {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh; // Để đảm bảo Spin nằm giữa màn hình
//   .ant-spin-dot {
//     font-size: 40px; // Tăng kích thước Spin nếu cần
//     .ant-spin-dot-item {
//       background-color: #1cef09; // Đổi màu cho Spin
//     }
//   }
// }



@media screen and (max-width: 1500px) {
  .info {
    p {
      font-size: 16px;
    }

    img {
      height: 240px;
    }
  }
  
  .total .number {
    font-size: 50px;
  }

  .detail .number {
    font-size: 45px;
  }
}

@media screen and (max-width: 1199.8px) {
  .info {
    p {
      font-size: 12px;
    }

    img {
      height: 170px;
    }
  }
  
  .total .number {
    font-size: 40px;
  }

  .detail .number {
    font-size: 35px;
  }
}

@media screen and (max-width: 991.8px) {
  .info {
    margin-left: 0;
    margin-top: 30px;
  }

  .info {
    p {
      font-size: 16px;
    }

    img {
      height: 240px;
    }
  }
  
  .total .number {
    font-size: 50px;
  }

  .detail .number {
    font-size: 45px;
  }
}

@media screen and (max-width: 767.8px){
  .info {
    p {
      font-size: 12px;
    }

    img {
      height: 200px;
    }
  }
  
  .total .number {
    font-size: 40px;
  }

  .detail .number {
    font-size: 35px;
  }
}

@media screen and (max-width: 575.8px){
  .info {
    p {
      font-size: 8px;
    }

    img {
      height: 200px;
    }
  }

  .title {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .total .number {
    font-size: 28px;
  }

  .detail .number {
    font-size: 24px;
  }
}

