.TongQuan {
    padding: 50px 200px 0 200px;
    background-color: #E3F5E8;

    span {
        color: #35973F;
    }

    img {
        width: 100px;
        height: 100px;
        margin-bottom: 0px;
    }

    p {
        font-size: 26px;
        font-weight: 600;
        text-align: justify;
        margin-bottom: 15px;
    }

}

.row {
    display: flex;
    justify-content: space-between;
}

.row .left {
    width: 45%;
}

.row .right {
    width: 50%;
}

.title {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
}

.image {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    
    img{
        width: 100%;
        height: auto;
        aspect-ratio: 4/3;
        object-fit: cover;
        box-shadow: 0px 4px 89px -8px #35973F;
        border-radius: 15px;
        margin-bottom: 40px;
    }
}

.image-left{
    display: flex;
}

.bg-success {
    background-color: #35973F;
}

.number {
    font-size: 90px;
    font-weight: 700;
    margin-top: 0;
}

.quy-title {
    font-size: 26px;
    font-weight: 700;
    color: #7c7c7c;
    margin-bottom: 0;
}

.number-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.number-bottom .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.number-bottom .item .item-title {
    font-size: 20px;
    color: #7c7c7c;
    line-height: 100%;
}

.number-bottom .item .item-number {
    font-size: 80px;
    // color: #35973F;
}



@media screen and (max-width: 1600px) {
    .TongQuan {
        padding: 50px 100px 0 100px;
    
        img {
            width: 100px;
            height: 100px;
            margin-bottom: 0px;
        }
    
        p {
            font-size: 26px;
            margin-bottom: 15px;
        }
    
    }
    
    .row .left {
        width: 45%;
    }
    
    .row .right {
        width: 50%;
    }
    
    .title {
        font-size: 48px;
        margin-bottom: 30px;
    }
    
    .image {      
        img{
            width: 100%;
            height: auto;
            aspect-ratio: 4/3;
            border-radius: 15px;
            margin-bottom: 40px;
        }
    }
    
    .number {
        font-size: 80px;
        margin-top: 0;
    }
    
    .quy-title {
        font-size: 26px;
        margin-bottom: 0;
    }
    
    .number-bottom {
        margin-top: 30px;
    }
    
    .number-bottom .item .item-title {
        font-size: 20px;
    }
    
    .number-bottom .item .item-number {
        font-size: 70px;
    }
}

@media screen and (max-width: 1199.8px) {
    .TongQuan {
        padding: 50px 50px 0 50px;
    
        img {
            width: 100px;
            height: 100px;
            margin-bottom: 0px;
            display: none;
        }
    
        p {
            font-size: 22px;
            margin-bottom: 15px;
        }
    
    }
    
    .row .left {
        width: 45%;
    }
    
    .row .right {
        width: 50%;
    }
    
    .title {
        font-size: 48px;
        margin-bottom: 30px;
    }
    
    .image {      
        img{
            width: 100%;
            height: auto;
            aspect-ratio: 4/3;
            border-radius: 15px;
            margin-bottom: 40px;
            display: inline-block;
        }
    }
    
    .number {
        font-size: 60px;
        margin-top: 0;
    }
    
    .quy-title {
        font-size: 22px;
        margin-bottom: 0;
    }
    
    .number-bottom {
        margin-top: 30px;
    }
    
    .number-bottom .item .item-title {
        font-size: 16px;
    }
    
    .number-bottom .item .item-number {
        font-size: 50px;
    }
}

@media screen and (max-width: 991.8px) {
    .TongQuan {
        padding: 50px 50px 0 50px;
    
        img {
            width: 100px;
            height: 100px;
            margin-bottom: 0px;
            display: none;
        }
    
        p {
            font-size: 22px;
            margin-bottom: 15px;
        }
    
    }
    
    .row .left {
        width: 45%;
    }
    
    .row .right {
        width: 50%;
    }
    
    .title {
        font-size: 36px;
        margin-bottom: 30px;
    }
    
    .image {      
        img{
            width: 100%;
            height: auto;
            aspect-ratio: 4/3;
            border-radius: 15px;
            margin-bottom: 40px;
            display: inline-block;
        }
    }
    
    .number {
        font-size: 50px;
        margin-top: 0;
    }
    
    .quy-title {
        font-size: 22px;
        margin-bottom: 0;
    }
    
    .number-bottom {
        margin-top: 30px;
    }
    
    .number-bottom .item .item-title {
        font-size: 16px;
        margin: 0 10px;
    }
    
    .number-bottom .item .item-number {
        font-size: 50px;
    }
}

@media screen and (max-width: 767.8px){
    .TongQuan {
        padding: 50px 50px 0 50px;
    
        img {
            width: 100px;
            height: 100px;
            margin-bottom: 0px;
            display: none;
        }
    
        p {
            font-size: 14px;
            margin-bottom: 15px;
        }
    
    }
    
    .row .left {
        width: 45%;
    }
    
    .row .right {
        width: 50%;
    }
    
    .title {
        font-size: 30px;
        margin-bottom: 30px;
    }
    
    .image {      
        img{
            width: 100%;
            height: auto;
            aspect-ratio: 4/3;
            border-radius: 15px;
            margin-bottom: 20px;
            display: inline-block;
        }
    }
    
    .number {
        font-size: 36px;
        margin-top: 0;
    }
    
    .quy-title {
        font-size: 16px;
        margin-bottom: 0;
    }
    
    .number-bottom {
        margin-top: 30px;
    }
    
    .number-bottom .item .item-title {
        font-size: 12px;
        margin: 0 10px;
    }
    
    .number-bottom .item .item-number {
        font-size: 30px;
    }
}

@media screen and (max-width: 575.8px){
    .TongQuan {
        padding: 50px 50px 0 50px;
    
        img {
            width: 100px;
            height: 100px;
            margin-bottom: 0px;
            display: none;
        }
    
        p {
            font-size: 14px;
            margin-bottom: 15px;
        }
    
    }

    .row {
        flex-wrap: wrap;
    }
    
    .row .left {
        width: 100%;
    }
    
    .row .right {
        width: 100%;
    }
    
    .title {
        font-size: 28px;
        margin-bottom: 30px;
    }
    
    .image {      
        img{
            width: 100%;
            height: auto;
            aspect-ratio: 4/3;
            border-radius: 15px;
            margin-bottom: 20px;
            display: inline-block;
        }
    }
    
    .number {
        font-size: 36px;
        margin-top: 0;
    }
    
    .quy-title {
        font-size: 16px;
        margin-bottom: 0;
    }
    
    .number-bottom {
        margin-top: 30px;
    }
    
    .number-bottom .item .item-title {
        font-size: 10px;
        margin: 0 5px;
        text-align: center;
    }
    
    .number-bottom .item .item-number {
        font-size: 36px;
    }
}