.container {
    display: flex;
    flex-direction: column;
    height: 100%;

    textArea:focus {
        outline: none;
    }
}

.containerHeader {
    display: flex;
    align-items: center;
    padding: 0.2rem;
    padding-left: 0.7rem;
    border-bottom: 2px solid rgb(223, 223, 223);
    margin-top: 0;
}

.containerHeader img {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    object-fit: cover;
}

.containerHeaderInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
}

.containerHeaderInfo p {
    font-size: 1rem;
    margin-bottom: 0;
}

.containerHeaderSettings {
    margin-left: auto;
    margin-right: 1rem;
    margin-top: 0;
}

.containerHeaderSettings img {
    width: 1.7rem;
    height: 1.7rem;
}

.content {
    flex: 1;
    overflow-y: scroll;
    padding: 1rem;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.content::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.footer {
    display: flex;
    flex-direction: column;
    
}.footerinput {
    display: flex;
    align-items: center;
    padding: 0.5rem;
}

.inputWrapper {
    position: relative;
    flex: 1;
}

.inputWrapper textarea {
    width: 100%;
    padding: 0.3rem;
    padding-top: 0.3rem;
    padding-right: 2.5rem; /* To leave space for the icon on the right */
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
    height: 2.5rem;
}

.inputWrapper i {
    position: absolute;
    right: 0.75rem;
    top: calc(50% - 4px);
    transform: translateY(-50%);
    font-size: 1.5rem;
    color: #1f682f;
}

.footerinput button {
    padding: 0.5rem 1rem;
    background-color: #35973F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 0.3rem;
    margin-bottom: 0.3rem;
}

.footer button:hover {
    background-color: rgb(87, 206, 81);
}.selectedImagePreview img{
    width: 8rem;
    height: auto;
    margin-bottom: 1rem;
    margin-left: 1rem;
    border-radius: 10px;
}

.msg {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}.msg img{
    max-width: 30%;
    height: auto;
    margin-top: 0.3rem;
    border-radius: 10px;
}

.alignStart {
    align-items: flex-start;
}

.alignEnd {
    align-items: flex-end;
}

.msgContent {
    background-color: #f1f1f1;
    padding: 8px 12px;
    border-radius: 18px 4px 18px 18px;
    max-width: 70%;
    margin-bottom: 0;
    background-color: rgb(37, 148, 39);
    color: #ffffff;
}

.msgTime {
    font-size: 1rem;
    color: #888;
    margin-top: 0;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}.msgContentFromUser {
    background-color: rgb(37, 148, 39); /* Màu xanh */
    color: #ffffff;
    margin-left: auto;
}

.msgContentFromOther {
    background-color: #d8d8d8; /* Màu xám */
    color: #000000;
    border-radius: 4px 18px 18px 18px;
}
.containerHeader i {
   display: none;
}@media (max-width : 560px) {
    .containerHeader img{
    margin-left: 0.4rem;
    width: 2.7rem;
    height: 2.7rem;
    margin-top: 0.4rem;
}.containerHeaderInfo p {
    font-size: 0.8rem;
    margin-top: 0;
}.containerHeaderInfo h5 {
    font-size: 1rem;
}.containerHeaderSettings img {
    width: 1.5rem;
    height: 1.5rem;
}.containerHeader i {
   display: block;
}
}