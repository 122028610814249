
.box {
    background-color: #9eddc2;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 60px);
    width: 100%; 
}
.container{
    background-color: #ffffff;
    border-radius: 35px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    position: relative;
    overflow: hidden;
    width: 768px !important;
    max-width: 100%;
    min-height: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    z-index: 1;
    margin-bottom: 8rem;
}

.container p{
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 20px 0;
}

.container span{
    font-size: 12px;
}

.container a{
    color: #333;
    font-size: 13px;
    text-decoration: none;
    margin: 15px 0 10px;
}

.container button{
    background-color: #1a643f;
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
}

.container button.hidden{
    background-color: transparent;
    border-color: #fff;
}

.container form{
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    height: 100%;
}

.container input{
    background-color: #eee;
    border: none;
    margin: 8px 0;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 8px;
    width: 100%;
    outline: none;
}

.form-container{
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-in{
    left: 0;
    width: 50%;
    z-index: 2;
}

.container.active .sign-in{
    transform: translateX(100%);
}

.sign-up{
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.container.active .sign-up{
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: move 0.6s;
}

@keyframes move{
    0%, 49.99%{
        opacity: 0;
        z-index: 1;
    }
    50%, 100%{
        opacity: 1;
        z-index: 5;
    }
}

.social-icons{
    margin: 20px 0;
}

.social-icons a{
    border: 1px solid #ccc;
    border-radius: 20%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    width: 40px;
    height: 40px;
}

.toggle-container{
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    border-radius: 150px 0 0 100px;
    z-index: 1000;
}

.container.active .toggle-container{
    transform: translateX(-100%);
    border-radius: 0 150px 100px 0;
}

.toggle{
    height: 100%;
    background-color: #1a643f;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fdfdfd;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.container.active .toggle{
    transform: translateX(50%);
}

.toggle-panel{
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 30px;
    text-align: center;
    top: 0;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.toggle-left{
    transform: translateX(-200%);
}

.container.active .toggle-left{
    transform: translateX(0);
}

.toggle-right{
    right: 0;
    transform: translateX(0);
}

.container.active .toggle-right{
    transform: translateX(200%);
}.errorMessage {
    color: red;
    font-size: 14px !important; 
    margin-top: 0;
    margin-bottom: 0;
}

.inputError {
  border-color: red !important; 
}

@media (max-width: 1600px) {

    .box {
        background-color: #9eddc2;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: calc(100vh - 60px);
        width: 100%; 
    }
    .container{
        background-color: #ffffff;
        border-radius: 35px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
        position: relative;
        overflow: hidden;
        width: 768px !important;
        max-width: 100%;
        min-height: 480px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 5rem;
        z-index: 1;
        margin-bottom: 8rem;
    }
    
    .container p{
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.3px;
        margin: 20px 0;
    }
    
    .container span{
        font-size: 12px;
    }
    
    .container a{
        color: #333;
        font-size: 13px;
        text-decoration: none;
        margin: 15px 0 10px;
    }
    
    .container button{
        background-color: #1a643f;
        color: #fff;
        font-size: 12px;
        padding: 10px 45px;
        border: 1px solid transparent;
        border-radius: 8px;
        font-weight: 600;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-top: 10px;
        cursor: pointer;
    }
    
    .container button.hidden{
        background-color: transparent;
        border-color: #fff;
    }
    
    .container form{
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 40px;
        height: 100%;
    }
    
    .container input{
        background-color: #eee;
        border: none;
        margin: 8px 0;
        padding: 10px 15px;
        font-size: 13px;
        border-radius: 8px;
        width: 100%;
        outline: none;
    }
    
    .form-container{
        position: absolute;
        top: 0;
        height: 100%;
        transition: all 0.6s ease-in-out;
    }
    
    .sign-in{
        left: 0;
        width: 50%;
        z-index: 2;
    }
    
    .container.active .sign-in{
        transform: translateX(100%);
    }
    
    .sign-up{
        left: 0;
        width: 50%;
        opacity: 0;
        z-index: 1;
    }
    
    .container.active .sign-up{
        transform: translateX(100%);
        opacity: 1;
        z-index: 5;
        animation: move 0.6s;
    }
    
    @keyframes move{
        0%, 49.99%{
            opacity: 0;
            z-index: 1;
        }
        50%, 100%{
            opacity: 1;
            z-index: 5;
        }
    }
    
    .social-icons{
        margin: 20px 0;
    }
    
    .social-icons a{
        border: 1px solid #ccc;
        border-radius: 20%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 3px;
        width: 40px;
        height: 40px;
    }
    
    .toggle-container{
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        height: 100%;
        overflow: hidden;
        transition: all 0.6s ease-in-out;
        border-radius: 150px 0 0 100px;
        z-index: 1000;
    }
    
    .container.active .toggle-container{
        transform: translateX(-100%);
        border-radius: 0 150px 100px 0;
    }
    
    .toggle{
        height: 100%;
        background-color: #1a643f;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: #fdfdfd;
        position: relative;
        left: -100%;
        height: 100%;
        width: 200%;
        transform: translateX(0);
        transition: all 0.6s ease-in-out;
    }
    
    .container.active .toggle{
        transform: translateX(50%);
    }
    
    .toggle-panel{
        position: absolute;
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 30px;
        text-align: center;
        top: 0;
        transform: translateX(0);
        transition: all 0.6s ease-in-out;
    }
    
    .toggle-left{
        transform: translateX(-200%);
    }
    
    .container.active .toggle-left{
        transform: translateX(0);
    }
    
    .toggle-right{
        right: 0;
        transform: translateX(0);
    }
    
    .container.active .toggle-right{
        transform: translateX(200%);
    }.errorMessage {
        color: red;
        font-size: 14px !important; 
        margin-top: 0;
        margin-bottom: 0;
    }
    
    .inputError {
      border-color: red !important; 
    }

}

@media (max-width: 575px) {
    .container{
        width: 768px;
        max-width: 90%;
    }
    
    .container p{
        font-size: 16px;
        margin: 20px 0;
    }
    
    .container span{
        font-size: 13px;
    }
    
    .container a{
        margin: 15px 0 5px;
    }
    
    .container button{
        margin-top: 5px;
        
    }
    .container form{
        padding: 0 20px;
    }
}
@media (max-width: 469px) {
    .container{
        width: 768px;
        max-width: 95%;
    }
    .container form{
        padding: 0 5px;
    }
}
