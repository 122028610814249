@font-face {
    font-family: 'RaleWay';
    src: url('../../../component/Fonts/Raleway/Raleway-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'Radio Canada';
    src: url('../../../component/Fonts/Radio_Canada/RadioCanada-VariableFont_wdth\,wght.ttf');
}



div.main {
    width: 100%;
    display: flex;
}


div.main div.div1 {
    width: 50%;
    height: 789px;
}


div.main div.div1 img {
    width: 100%;
    height: 789px;
}


div.main div.div2 {
    width: 50%;
    height: 789px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    background: #1D0356;

    /* border: 1px solid red; */
}


div.main div.div2 p.p1 {
    width: 80%;
    /* height: 114px; */
    flex-shrink: 0;

    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    text-shadow: 0px 4px 22px rgba(255, 255, 255, 0.25);
  
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%; /* 44.2px */

    /* border: 1px solid red; */
}


div.main div.div2 div.signs {
    width: 70%;
    /* height: 322px; */
    flex-shrink: 0;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


div.main div.div2 div.signs div.sign {
    width: 50%;
    margin-bottom: 30px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}


div.main div.div2 div.signs div.sign img {
    width: 65px;
    height: 65px;
    flex-shrink: 0;
}


div.main div.div2 div.signs div.sign p {
    width: 100%;
    /* height: 67px; */
    flex-shrink: 0;

    margin-top: 12px;

    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
 
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 29.9px */
    text-align: center;
}


div.main div.div2 div.bt1 button {
    width: 295px;
    height: 76px;
    flex-shrink: 0;

    padding-right: 30px;

    border-radius: 20px;
    background: #3AB137;

    border: 0px solid white;

    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
  
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 35.1px */
}


div.main div.div2 div.bt1 img {
    width: 37px;
    height: 37px;
    flex-shrink: 0;

    margin-left: -70px;
    margin-top: -10px;
}





/* Scrolling animation */
.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(100%);
    transition: all 1s;
}


.show {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
}


@media screen and (max-width: 1600px) {
    div.main div.div1 {
        height: 600px;
    }
    
    div.main div.div1 img {
        width: 100%;
        height: 100%;
    }
    
    div.main div.div2 {
        width: 50%;
        height: 600px;
    }
    
    div.main div.div2 p.p1 {
        width: 80%;
        font-size: 26px;
    }
    
    div.main div.div2 div.signs {
        width: 80%;
    }
    
    div.main div.div2 div.signs div.sign {
        width: 50%;
        margin-bottom: 25px;
    }
     
    div.main div.div2 div.signs div.sign img {
        width: 50px;
        height: 50px;
    }
    
    
    div.main div.div2 div.signs div.sign p {
        margin-top: 12px;
        font-size: 20px;
    }
    
    
    div.main div.div2 div.bt1 button {
        width: 250px;
        height: 60px;
        padding-right: 30px;
        font-size: 24px;
    }
    
    
    div.main div.div2 div.bt1 img {
        width: 30px;
        height: 30px;
        margin-left: -70px;
        margin-top: -10px;
    }
}

@media screen and (max-width: 1199.8px) {
    div.main div.div1 {
        height: 500px;
    }
    
    div.main div.div1 img {
        width: 100%;
        height: 100%;
    }
    
    div.main div.div2 {
        width: 50%;
        height: 500px;
    }
    
    div.main div.div2 p.p1 {
        width: 80%;
        font-size: 22px;
    }
    
    div.main div.div2 div.signs {
        width: 80%;
    }
    
    div.main div.div2 div.signs div.sign {
        width: 50%;
        margin-bottom: 20px;
    }
     
    div.main div.div2 div.signs div.sign img {
        width: 40px;
        height: 40px;
    }
    
    
    div.main div.div2 div.signs div.sign p {
        margin-top: 12px;
        font-size: 16px;
    }
    
    
    div.main div.div2 div.bt1 button {
        width: 200px;
        height: 50px;
        border-radius: 10px;
        padding-right: 20px;
        font-size: 20px;
    }
    
    
    div.main div.div2 div.bt1 img {
        width: 20px;
        height: 20px;
        margin-left: -50px;
        margin-top: -10px;
    }
}

@media screen and (max-width: 991.8px) {
    div.main {
        flex-wrap: wrap;
    }
    div.main div.div1 {
        height: 500px;
        width: 100%;
    }
    
    div.main div.div1 img {
        width: 100%;
        height: 100%;
    }
    
    div.main div.div2 {
        width: 100%;
        height: 500px;
    }
    
    div.main div.div2 p.p1 {
        width: 80%;
        font-size: 24px;
    }
    
    div.main div.div2 div.signs {
        width: 80%;
    }
    
    div.main div.div2 div.signs div.sign {
        width: 50%;
        margin-bottom: 30px;
    }
     
    div.main div.div2 div.signs div.sign img {
        width: 50px;
        height: 50px;
    }
    
    
    div.main div.div2 div.signs div.sign p {
        margin-top: 12px;
        font-size: 20px;
    }
    
    
    div.main div.div2 div.bt1 button {
        width: 200px;
        height: 50px;
        border-radius: 10px;
        padding-right: 20px;
        font-size: 20px;
        margin-bottom: 20px;
    }
    
    
    div.main div.div2 div.bt1 img {
        width: 20px;
        height: 20px;
        margin-left: -50px;
        margin-top: -10px;
    }
}

@media screen and (max-width: 767.8px){
    div.main {
        flex-wrap: wrap;
    }
    div.main div.div1 {
        height: 400px;
        width: 100%;
    }
    
    div.main div.div1 img {
        width: 100%;
        height: 100%;
    }
    
    div.main div.div2 {
        width: 100%;
        height: 400px;
    }
    
    div.main div.div2 p.p1 {
        width: 80%;
        font-size: 24px;
        margin-top: 20px;
    }
    
    div.main div.div2 div.signs {
        width: 80%;
    }
    
    div.main div.div2 div.signs div.sign {
        width: 50%;
        margin-bottom: 20px;
    }
     
    div.main div.div2 div.signs div.sign img {
        width: 40px;
        height: 40px;
    }
    
    
    div.main div.div2 div.signs div.sign p {
        margin-top: 12px;
        font-size: 18px;
    }
    
    
    div.main div.div2 div.bt1 button {
        width: 200px;
        height: 50px;
        border-radius: 10px;
        padding-right: 20px;
        font-size: 20px;
        margin-bottom: 20px;
    }
    
    
    div.main div.div2 div.bt1 img {
        width: 20px;
        height: 20px;
        margin-left: -50px;
        margin-top: -10px;
    }
}

@media screen and (max-width: 575.8px){
    div.main {
        flex-wrap: wrap;
    }
    div.main div.div1 {
        height: 300px;
        width: 100%;
    }
    
    div.main div.div1 img {
        width: 100%;
        height: 100%;
    }
    
    div.main div.div2 {
        width: 100%;
        height: 350px;
    }
    
    div.main div.div2 p.p1 {
        width: 90%;
        font-size: 18px;
        margin-top: 20px;
    }
    
    div.main div.div2 div.signs {
        width: 80%;
    }
    
    div.main div.div2 div.signs div.sign {
        width: 50%;
        margin-bottom: 10px;
    }
     
    div.main div.div2 div.signs div.sign img {
        width: 30px;
        height: 30px;
    }
    
    
    div.main div.div2 div.signs div.sign p {
        margin-top: 5px;
        font-size: 14px;
    }
    
    
    div.main div.div2 div.bt1 button {
        width: 150px;
        height: 32px;
        border-radius: 8px;
        padding-right: 0px;
        font-size: 20px;
        margin-bottom: 16px;
    }
    
    
    div.main div.div2 div.bt1 img {
        display: none;
    }
}