.ThongTinThem {
  width: calc(100% - 200px);
  margin-left: 100px;
  margin-top: 0px;
  background-color: white;
  padding-top: 60px;
  padding-left: 50px;
  display: flex;
  flex-wrap: wrap;

  hr {
    width: 150px;
    border: 2px solid #35973F;
    opacity: 1;
    margin-bottom: 10px;
    margin-top: 0px;
  }
}

.contact {
  width: 50%;
}

.organizations {
  width: 50%;
}

.title {
  font-size: 30px;
  font-weight: 600;
  color: #35973F;
  margin-bottom: 20px;
}

.item {
  margin-bottom: 30px;
}

.item .desc{
  font-size: 24px;
  font-weight: 500;
  color: #767676;
  margin-bottom: 5px;
}

.item .title {
  font-size: 28px;
  font-weight: 600;
  color: black;
  margin-bottom: 5px;
}

.item .email{
  font-size: 24px;
  font-weight: 600;
  color: #35973F;
  margin-bottom: 5px;
}

.organizations .logo {
  img {
    width: 120px;
    height: 120px;
    margin-bottom: 15px;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    color: black;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 1600px) {
.ThongTinThem {
  width: calc(100% - 200px);
  margin-left: 100px;
  margin-top: 0px;
  background-color: white;
  padding-top: 60px;
  padding-left: 50px;
  display: flex;
  flex-wrap: wrap;

  hr {
    width: 100px;
    border: 2px solid #35973F;
    opacity: 1;
    margin-bottom: 10px;
    margin-top: 0px;
  }
}

.contact {
  width: 50%;
}

.organizations {
  width: 50%;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: #35973F;
  margin-bottom: 20px;
}

.item {
  margin-bottom: 30px;
}

.item .desc{
  font-size: 20px;
  font-weight: 500;
  color: #767676;
  margin-bottom: 5px;
}

.item .title {
  font-size: 24px;
  font-weight: 600;
  color: black;
  margin-bottom: 5px;
}

.item .email{
  font-size: 16px;
  font-weight: 600;
  color: #35973F;
  margin-bottom: 5px;
}

.organizations .logo {
  img {
    width: 120px;
    height: 120px;
    margin-bottom: 15px;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    color: black;
    margin-bottom: 25px;
  }
}


  
}

@media screen and (max-width: 1199.8px) {
  .ThongTinThem {
    width: calc(100% - 120px);
    margin-left: 60px;
  }

}

@media screen and (max-width: 991.8px) {
  .ThongTinThem {
    width: calc(100% - 80px);
    margin-left: 40px;
  }
  .item .title {
    font-size: 16px;
  }
}

@media screen and (max-width: 767.8px){
  .ThongTinThem {
    width: calc(100% - 40px);
    margin-left: 20px;
    display: flex;
  }

  .ThongTinThem .contact {
    width: 100%;
  }
  
  .ThongTinThem .organizations {
    width: 100%;
  }
}

@media screen and (max-width: 575.8px){
  .ThongTinThem {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-top: 0px;
    background-color: white;
    padding-top: 60px;
    padding-left: 20px;
  
    hr {
      width: 150px;
      border: 2px solid #35973F;
      opacity: 1;
      margin-bottom: 10px;
      margin-top: 0px;
    }
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    color: #35973F;
    margin-bottom: 20px;
  }

  .ThongTinThem .contact {
    width: 100%;
  }
  
  .ThongTinThem .organizations {
    width: 100%;
  }
}