.Compose {
    padding: 0 100px;

    input, textarea {
        font-size: 18px;

        &:focus {
            border: 1px solid #35973F;
            outline: none;
            box-shadow: none;
        }
    }
}

.card-header-email {
    background-color: #35973F;
    padding: 10px 20px;
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.btn-compose {
    background-color: #35973F;
    border: #35973F;
}

.badge-green {
    background-color: #35973F;
    border: #35973F;
}

.nav-link-gray {
    color: #606060;

    &:hover {
        color: #35973F;
    }
}

.content textarea{
    min-height: 60vh;
}