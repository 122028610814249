.footer {
  background-color: #2e8b57;
  color: #ffffff;
  text-align: center;
  padding: 0 20px;
  font-family: "Montserrat", sans-serif;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  padding: 20px 10px;
}

.footer-section {
  flex: 1 1 200px;
  margin: 20px;
}

.footer-logo, .footer-contact {
  width: 25%;
}

.footer-links {
  width: 15%;
}

.footer-newsletter {
  width: 35%;
  padding-left: 3%;
}

.footer-logo img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.footer-logo p {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.footer-links h3, .footer-newsletter h3, .footer-contact h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.footer-links ul, .footer-newsletter ul {
  list-style: none;
  padding: 0;
}

.footer-newsletter ul {
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;
}

.footer-links ul li, .footer-newsletter ul li {
  margin: 5px 0;
  font-size: 18px;
  font-weight: 600;
}

.footer-links ul li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
}

.footer-newsletter ul li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
}

.footer-newsletter ul li {
  display: inline-block;
}

.footer-newsletter ul li a {
  background-color: white;
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  color: #05390C;
  text-align: center;
  line-height: 32px;
  font-size: 16px;
  margin-right: 5px;
}

.footer-links ul li a:hover, .footer-newsletter ul li a:hover {
  text-decoration: underline;
}

.footer-contact p {
  font-size: 18px;
  font-weight: 600;
  margin: 5px 0;
}

.footer-newsletter form {
  display: flex;
  align-items: center;
}

.newsletter-form {
  width: 100%;
  display: flex;
}

.footer-newsletter input[type="email"] {
  padding: 10px;
  border: none;
  border-radius: 10px 0 0 10px;
  flex: 1;
}

.footer-newsletter button {
  padding: 10px 15px;
  border: none;
  border-radius: 0 10px 10px 0;
  background-color: #1e6d46;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
}

.footer-newsletter button:hover {
  background-color: #145236;
}

.footer-bottom {
  background-color: #1e6d46;
  padding: 20px;
}

.footer-bottom img {
  width: 400px;
  object-fit: contain;
}.footer-newsletter ul {
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;
}

.footer-newsletter ul li {
  display: flex; /* Sử dụng flexbox */
  align-items: center; /* Căn giữa theo chiều dọc */
  margin: 5px 5px; /* Khoảng cách giữa các mục */
}

.footer-newsletter ul li a {
  background-color: white;
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  color: #05390C;
  text-align: center;
  line-height: 32px;
  font-size: 16px;
  margin-right: 10px; /* Tăng khoảng cách giữa logo và chữ */
}

.footer-newsletter ul li span { 
  font-size: 14px; /* Thiết lập kiểu chữ cho phần chữ */
  color: #ffffff; /* Màu chữ */
}

/* Responsive Design */
@media (max-width: 1024px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    width: 80%;
    margin: 10px 0;
  }

  .footer-newsletter {
    padding-left: 0;
  }

  .footer-logo img {
    width: 50%;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .footer-logo p, .footer-contact p {
    font-size: 14px;
  }

  .footer-links ul li a, .footer-newsletter ul li a {
    font-size: 16px;
  }

  .footer-newsletter ul {
    flex-direction: column;
    align-items: center;
  }

  .footer-newsletter ul li {
    margin-bottom: 10px;
  }

  .footer-newsletter ul li a {
    margin: 0;
  }

  .footer-newsletter ul li a:not(:last-child) {
    margin-bottom: 5px;
  }

  .footer-newsletter form {
    flex-direction: column;
  }

  .newsletter-form {
    flex-direction: column;
    align-items: center;
  }

  .footer-newsletter input[type="email"], .footer-newsletter button {
    width: 80%;
    border-radius: 10px;
    margin: 5px 0;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 0 10px;
  }

  .footer-section {
    flex: 1;
    margin: 20px;
  }
  

  .footer-logo img {
    width: 100%;
    height: auto;
    object-fit: contain;
    display: none;
  }
  
  .footer-logo p {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    display: none;
  }

  .footer-section {
    width: 100%;
    margin: 10px 0;
  }

  .footer-links{
    display: none;
  }

  .footer-newsletter ul{
    display: none;
  }

  .footer-logo p, .footer-contact p {
    font-size: 14px;
  }

  .footer-links ul li a, .footer-newsletter ul li a {
    font-size: 14px;
  }

  .footer-newsletter input[type="email"], .footer-newsletter button {
    width: 100%;
  }

  .footer-newsletter form {
    width: 100%;
  }
}