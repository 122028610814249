.TacDong {
  padding-top: 60px;
  padding-left: 100px;
  padding-right: 100px;

  pre {
    white-space: pre-wrap; // Giữ nguyên ngắt dòng và cho phép ngắt dòng tự động
    word-wrap: break-word; // Tự động ngắt dòng khi từ quá dài
  }
}

.title {
  font-size: 32px;
  font-weight: 600;
  color: #6A6A6A;
  margin-bottom: 40px;
  padding-top: 20px;
  border-top: 4px solid #35973F;
}

.left {
  margin-right: 100px;
}

.number {
  font-size: 85px;
  font-weight: 600;
  color: #35973F;
  line-height: 100%;
  margin-bottom: 40px;
}

.desc {
  font-size: 60px;
  font-weight: 600;
  color: black;
  line-height: 100%;
}

.icon {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 30px;
  }

  li {
    list-style-type: none;
    font-size: 50px;
    color: black;
  }
}

.mo {
  opacity: 0.5;
}

// Trong file CSS/SCSS của bạn
.custom-tooltip {
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  z-index: 999;
}

.custom-tooltip::after {
  border-top-color: #333; // Màu của mũi tên tooltip
}

.title-progress {
  font-size: 32px;
  font-weight: 600;
  color: #6A6A6A;
  margin-bottom: 0px;
  padding-top: 20px;
  border-top: 4px solid #35973F;
}
.progress-number .number-separate{
    font-size: 50px;
    font-weight: 700;
    color: #6A6A6A;
}

.progress-number .number-current {
  font-size: 60px;
  font-weight: 600;
  color: #35973F;
  line-height: 100%;
}

.progress-number .number-total {
  font-size: 40px;
  font-weight: 600;
  color: #6A6A6A;
  line-height: 100%;
}

.sub-progress-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub-progress-number .sub {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sub-progress-number .sub .sub-title {
  font-size: 24px;
  font-weight: 600;
  color: #6A6A6A;
  line-height: 100%;
  margin-top: 10px;
}

.sub-progress-number .sub .sub-number {
  font-size: 30px;
  font-weight: 600;
  color: #35973F;
  line-height: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}




@media screen and (max-width: 1600px) {
.TacDong {
  padding-top: 60px;
  padding-left: 100px;
  padding-right: 100px;

  pre {
    white-space: pre-wrap; // Giữ nguyên ngắt dòng và cho phép ngắt dòng tự động
    word-wrap: break-word; // Tự động ngắt dòng khi từ quá dài
  }
}

.title {
  font-size: 26px;
  font-weight: 600;
  color: #6A6A6A;
  margin-bottom: 40px;
  padding-top: 20px;
  border-top: 2px solid #35973F;
}

.left {
  margin-right: 100px;
}

.number {
  font-size: 70px;
  font-weight: 600;
  color: #35973F;
  line-height: 100%;
  margin-bottom: 40px;
}

.desc {
  font-size: 50px;
  font-weight: 600;
  color: black;
  line-height: 100%;
}

.icon {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 30px;
  }

  li {
    list-style-type: none;
    font-size: 40px;
    color: black;
  }
}

.mo {
  opacity: 0.5;
}

}

.title-progress {
  font-size: 26px;
  margin-bottom: 0px;
  padding-top: 20px;
  border-top: 2px solid #35973F;
}
.progress-number .number-separate{
    font-size: 40px;
}

.progress-number .number-current {
  font-size: 45px;
}

.progress-number .number-total {
  font-size: 30px;
}

.sub-progress-number .sub .sub-title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
}

.sub-progress-number .sub .sub-number {
  font-size: 26px;
  margin-top: 10px;
  margin-bottom: 20px;
}


@media screen and (max-width: 1500px) {
  .TacDong {
    padding-top: 40px;
    padding-left: 100px;
    padding-right: 100px;
  
    pre {
      white-space: pre-wrap; // Giữ nguyên ngắt dòng và cho phép ngắt dòng tự động
      word-wrap: break-word; // Tự động ngắt dòng khi từ quá dài
    }
  }
  
  .title {
    font-size: 20px;
    font-weight: 600;
    color: #6A6A6A;
    margin-bottom: 40px;
    padding-top: 20px;
    border-top: 2px solid #35973F;
  }
  
  .left {
    margin-right: 100px;
  }
  
  .number {
    font-size: 60px;
    font-weight: 600;
    color: #35973F;
    line-height: 100%;
    margin-bottom: 40px;
  }
  
  .desc {
    font-size: 50px;
    font-weight: 600;
    color: black;
    line-height: 100%;
  }
  
  .icon {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 30px;
    }
  
    li {
      list-style-type: none;
      font-size: 40px;
      color: black;
    }
  }
  
  .mo {
    opacity: 0.5;
  }

  .title-progress {
    font-size: 20px;
    margin-bottom: 0px;
    padding-top: 20px;
    border-top: 2px solid #35973F;
  }
  .progress-number .number-separate{
      font-size: 35px;
  }
  
  .progress-number .number-current {
    font-size: 35px;
  }
  
  .progress-number .number-total {
    font-size: 25px;
  }
  
  .sub-progress-number .sub .sub-title {
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
  }
  
  .sub-progress-number .sub .sub-number {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1199.8px) {
  .number {
    font-size: 50px;
  }
  
  .desc {
    font-size: 40px;
  }

  .TacDong {
    padding-left: 60px;
    padding-right: 60px;
  }

  .title-progress {
    font-size: 20px;
    margin-bottom: 0px;
    padding-top: 20px;
    border-top: 2px solid #35973F;
  }
  .progress-number .number-separate{
      font-size: 28px;
  }
  
  .progress-number .number-current {
    font-size: 28px;
  }
  
  .progress-number .number-total {
    font-size: 20px;
  }
  
  .sub-progress-number .sub .sub-title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
  }
  
  .sub-progress-number .sub .sub-number {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 991.8px) {
  .number {
    font-size: 35px;
  }
  .desc {
    font-size: 28px;
  }
  .TacDong {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 767.8px){
  .TacDong {
    padding-left: 20px;
    padding-right: 20px;
  }
  .number {
    font-size: 28px;
  }
  .icon {
    li {
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 575.8px){
  .TacDong {
    padding-top: 0px;
  }
  
  .title {
    font-size: 20px;
    font-weight: 600;
    color: #6A6A6A;
    margin-bottom: 10px;
    padding-top: 10px;
    border-top: 4px solid #35973F;
  }
  
  .left {
    margin-right: 0px;
  }
  
  .number {
    font-size: 45px;
    font-weight: 600;
    color: #35973F;
    line-height: 100%;
    margin-bottom: 20px;
  }
  
  .desc {
    font-size: 40px;
    font-weight: 600;
    color: black;
    line-height: 100%;
    margin-bottom: 20px;
  }
  
  .icon {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0;
    }
  
    li {
      list-style-type: none;
      font-size: 35px;
      color: black;
    }
  }
  
}