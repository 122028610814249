.mapVietNam {
  width: 100%;
  height: auto;
  max-width: 1000px;
  display: block;
  margin: auto;

  path {
    fill: red;
    cursor: pointer;
    transition: fill 0.3s ease;

    &:hover {
      fill: #ffdc18; 
    }
  }


}

.mapVietNam .selected {
  fill: #ffdc18;
}

.tooltip {
  position: absolute;
  background-color: rgb(123, 123, 116);
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  pointer-events: none;
  visibility: visible;
  z-index: 1000;
  font-size: 12px;
  white-space: nowrap;
}

