.Read {
    padding: 0 100px;
}

.btn-compose {
    background-color: #35973F;
    border: #35973F;
}

.badge-green {
    background-color: #35973F;
    border: #35973F;
}

.nav-link-gray {
    color: #606060;

    &:hover {
        color: #35973F;
    }
}

.card-header-mailbox {
    background-color: #35973F;
    padding: 2px 0;
}

.mailbox-read-info {
    border-bottom: 1px solid #dee2e6;
    padding:15px;
}

.mailbox-read-time {
    color: #868e96;
    font-size: 14px;
}

.mailbox-read-message {
    padding: 15px;
    font-size: 18px;
    min-height: 75vh;
}