.container {
    width: 100%;
    height: 100%;
    margin-top: 0;
    position: relative;
    background-color: rgb(29, 29, 29);
    padding-top: 1rem;
    overflow: hidden;
}

.flexContainer {
    display: flex;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
}

.videoContainer {
    margin-bottom: 0;
    margin-right: 0rem;
    flex: 1.5;
    margin-right: 1rem;
    transition: flex 0.3s ease-in-out; /* Thêm transition */
}

.videoElement {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 47rem;
    margin-top: 0;
    background-color: black;
}

.videoContainer.shrinked {
    flex: 1; /* Khi chatBox xuất hiện, videoContainer sẽ co lại */
}

.chatBox {
    background-color: white;
    width: 0;
    height: 47rem;
    margin-top: 0;
    border-radius: 10px;
    margin-right: 2rem;
    padding-top: 0.5rem;
    position: relative;
    transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Thêm transition cho opacity */
    opacity: 0; /* Ẩn ban đầu */
    visibility: hidden; 
    z-index: 500;
}

.chatBox.visible {
    width: 25%;
    opacity: 1; 
    visibility: visible;
}

.component {
    background-color: white;
    width: 100%;
    height: 88%;
    margin-top: 0;
    position: relative;
    padding-top: 0.5rem;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 1rem;
}

.buttonContainer button {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 1px solid rgb(182, 182, 182);
    background-color: #ffffff;
}

.draggableVideo {
    width: 10rem;
    height: 10rem;
    position: absolute;
    top: 0rem;
    z-index: 1000;
    cursor: move;
}

.tab {
    cursor: pointer;
    padding: 0;
    transition: color 0.3s ease-in-out;
    position: relative;
    display: inline-block;
    width: 33%; 
    font-weight : 500;
}

.activeTab {
    color: green;
}

.underline {
    position: absolute;
    height: 3px;
    width: 33%;
    background-color: rgb(3, 116, 3);
    transition: left 0.3s ease-in-out;
}

.options {
    margin-bottom: 0.3rem;
    text-align: center;
}

.footer {
    height:  3rem;
}

@media (max-width: 1700px) {
    .footer {
        height: 2rem;
    }
}

@media (max-width: 1250px) {
    .flexContainer {
        flex-direction: column;
    }
    
    .videoContainer {
        flex: 1;
        position: relative;
        height: 38rem;
    }

    .chatBox {
        width: 100%; /* Chiều rộng của chatBox chiếm 100% */
        height: 38rem;
        margin-top: 0;
        margin-right: 0;
        padding-top: 0.5rem;
        position: absolute; /* Đặt chatBox vào vị trí tuyệt đối */
        top: 0;
        left: 0;
        z-index: 500; 
        border-radius: 0; 
        opacity: 0;
        visibility: hidden;
        transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    .chatBox.visible {
        margin-top : 1rem;
        width : 100%;
        opacity: 1;
        visibility: visible;
        height: 40rem;
    }.buttonContainer {
    margin-top: 0;
}.footer {
        height: 4rem;
    }
}
