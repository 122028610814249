@font-face {
    font-family: 'RaleWay';
    src: url('../../../component/Fonts/Raleway/Raleway-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'Radio Canada';
    src: url('../../../component/Fonts/Radio_Canada/RadioCanada-VariableFont_wdth\,wght.ttf');
}


div.main {
    width: 100%;
    flex-shrink: 0;
    margin: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}


div.main div.divIn {
    width: 92%;
    flex-shrink: 0;
    padding: 50px 200px;
    border-radius: 20px;
    background: rgba(118, 202, 146, 0.12);

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}


div.main div.divIn div.txt {
    width: 100%;
    margin-bottom: 30px;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    /* border: 1px solid red; */
}


div.main div.divIn div.txt h2 {
    width: 100%;
    
    color: #1E266D;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%; /* 44.2px */
}


div.main div.divIn div.txt p {
    width: 100%;

    color: #1E266D;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 28.6px */
}


div.main div.divIn div.bt {
    padding-right: 36px;
}


div.main div.divIn div.posts {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


div.main div.divIn div.posts .lnk {
    text-decoration: none;
    width: 30%;
}



/* Scrolling animation */
.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(100%);
    transition: all 1s;
}


.show {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
}


@media screen and (max-width: 1600px) {
    div.main {
        margin: 80px 0;
    }
    
    div.main div.divIn {
        width: 92%;
        padding: 50px 100px;
    }
    
    div.main div.divIn div.txt {
        margin-bottom: 30px;
    }
    
    div.main div.divIn div.txt h2 {
        font-size: 34px;
    }
    
    div.main div.divIn div.txt p {
        font-size: 24px;
    }
    
    div.main div.divIn div.bt {
        padding-right: 36px;
    }
    
    div.main div.divIn div.posts .lnk {
        width: 30%;
    }
}

@media screen and (max-width: 1199.8px) {

}

@media screen and (max-width: 991.8px) {
    div.main {
        margin: 80px 0;
    }
    
    div.main div.divIn {
        width: 92%;
        padding: 50px 50px;
    }
    
    div.main div.divIn div.txt {
        margin-bottom: 30px;
    }
    
    div.main div.divIn div.txt h2 {
        font-size: 28px;
    }
    
    div.main div.divIn div.txt p {
        font-size: 20px;
    }
    
    div.main div.divIn div.bt {
        padding-right: 36px;
    }
    
    div.main div.divIn div.posts .lnk {
        width: 30%;
    }
}

@media screen and (max-width: 767.8px){
    div.main {
        margin: 80px 0;
    }
    
    div.main div.divIn {
        width: 92%;
        padding: 50px 50px;
    }
    
    div.main div.divIn div.txt {
        margin-bottom: 30px;
    }
    
    div.main div.divIn div.txt h2 {
        font-size: 28px;
    }
    
    div.main div.divIn div.txt p {
        font-size: 20px;
    }
    
    div.main div.divIn div.bt {
        padding-right: 36px;
    }

    div.main div.divIn div.posts {
        justify-content: center;
    }
    
    div.main div.divIn div.posts .lnk {
        width: 70%;
        margin-bottom: 20px;
    }
    
}

@media screen and (max-width: 575.8px){
    div.main {
        margin: 50px 0;
    }
    
    div.main div.divIn {
        width: 92%;
        padding: 50px 20px;
    }
    
    div.main div.divIn div.txt {
        margin-bottom: 30px;
    }
    
    div.main div.divIn div.txt h2 {
        font-size: 22px;
    }
    
    div.main div.divIn div.txt p {
        font-size: 16px;
    }
    
    div.main div.divIn div.bt {
        padding-right: 36px;
    }
    
    div.main div.divIn div.posts {
        justify-content: center;
    }
    
    div.main div.divIn div.posts .lnk {
        width: 90%;
        margin-bottom: 20px;
    }
}